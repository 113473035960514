<template>
  <router-link
    :is="linkTo ? 'router-link' : 'div'"
    :to="linkTo"
  >
    <div v-if="!isLoading">
      <v-tooltip
        top
        max-width="300"
        :disabled="!displayTooltipWithText.length"
      >
        <template #activator="{ on }">
          <div v-on="on" class="d-flex align-center justify-center ga-1 mb-1">
            <span :class="[
              'lh-34',
              'f-34',
              'font-weight-bold',
              score === null ? 'c-lightgrey' : 'c-primary'
            ]">
              {{ displayedScore }}
            </span>
            <div>
              <span v-if="displayEvolution">
                <w-color-sign :value="numberEvolution" :decimals="2"/>
              </span>
            </div>
          </div>
        </template>
        <span v-if="!!displayTooltipWithText.length">
          {{ displayTooltipWithText }}
        </span>
      </v-tooltip>
      <div class="d-flex flex-column align-center ga-1">
        <v-rating
          v-if="displayStars"
          class="w-rating"
          background-color="grey lighten-2"
          readonly
          :value="scoreForStars"
          half-increments
          length="5"
          :size="$vuetify.breakpoint.name === 'xs' ? 24 : 32"
          :color="starsColor"
        />
        <div class="d-flex align-center ga-1">
          <span
            v-if="numberScore"
            class="sub-text f-12 c-lightgrey"
            v-html="translateNbReview()"
          />
          <span v-if="infoMessageReviews">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-if="infoLink"
                  :href="infoLink"
                  class="infoLink"
                  target="_blank"
                >
                  <v-icon
                    :class="`f-12 ${iconInfoReviewColor}`"
                    class="infoLink_icon"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </a>
                <v-icon v-else v-bind="attrs" v-on="on" :class="`f-12 ${iconInfoReviewColor}`" small>mdi-information-outline</v-icon>
              </template>
              <span>{{ infoMessageReviews }}</span>
            </v-tooltip>
          </span>
        </div>
      </div>
    </div>
    <v-skeleton-loader v-else type="paragraph" class="pt-6" />
  </router-link>
</template>

<script>
  import WWidget from '@theme/components/WWidget'
  import WColorSign from '@theme/components/WColorSign'

  export default {
    name: "WStatsScore",
    components: {
      WWidget,
      WColorSign
    },
    props: {
      score: { type: [Number, String], required: false },
      nbReview: { type: Number | null, required: true },
      nbReviewMessage: { type: String, required: false },
      evolution: { type: [Number, String], required: false, default: null },
      decimals: { type: Number, required: false, default: 0 },
      isFiveScore: { type: Boolean, default: false },
      source: { type: String, require: false},
      isLoading: { type: Boolean, default: false },
      infoLink: { type: String, require: false },
      infoMessageReviews: { type: String, require: false },
      iconInfoReviewColor: { type: String, required: false, default: 'c-lightgrey' },
      displayEvolution: {
        required: false,
        type: Boolean,
        default: true
      },
      displayStars: {
        required: false,
        type: Boolean,
        default: true
      },
      displayTooltipWithText: {
        required: false,
        type: String,
        default: ""
      },
      linkTo: { type: Object, required: false }
    },
    computed: {
      numberScore() {
        return Number(this.score)
      },
      numberEvolution() {
        return Number(this.evolution)
      },
      displayedScore: function() {
        return this.numberScore ? this.numberScore.toFixed(this.decimals) : "-"
      },
      scoreForStars: function() {
        if (this.numberScore === null) {
          return 5;
        }

        const score = this.isFiveScore ? this.numberScore : this.numberScore / 2
        return Math.round(score * 2) / 2;
      },
      starsColor: function() {
        return this.numberScore == null ? "rgba(0, 0, 0, 0.6)" : this.$colors.gold
      }
    },
    methods: {
      translateNbReview() {
        if (this.nbReviewMessage) {
          return this.nbReviewMessage
        } else {
          return this.$t(this.source && this.source === "google" ? 'nbReview_html' : 'nbReview_without_google_logo', {
            nbReview: this.nbReview?.toLocaleString()
          })
        }
      },
    },
  }
</script>

<style lang="stylus">
  .infoLink
    vertical-align: text-bottom

  .infoLink_icon
    position: relative
    top: -0.5px
</style>

import BasedQuery from "@api/based/basedQuery"
import _uniq from 'lodash/uniq'
import { i18n } from '@i18n/setup'

const scope = {
  state: () => ({
    selectedScope: {
      id: 'all'
    },
    label: null,
    icon: null,
    placeIds: null,
    campaignIds: null,
    placeCampaignIds: null,
    placeNameByLmGmbPlaceId: null,
    groupRanking: null,
    networkRanking: null,
  }),
  getters: {
    dashboardFilterScope(state, getters) {
      return state.selectedScope
    },
    placeIdsScope(state, getters) {
      if (state?.selectedScope?.id === 'all') {
        return null
      } else {
        return state?.placeIds
      }
    },
    placeIds(state, getters) {
      return state?.placeIds
    },
    placeCampaignIds(state, getters) {
      return state?.placeCampaignIds
    },
    campaignIdsScope(state, getters) {
      return state.campaignIds || []
    },
    lmGmbPlaceIdsScope(state, getters) {
      if (state.placeNameByLmGmbPlaceId) {
        return Object.keys(state.placeNameByLmGmbPlaceId)?.map(e => Number(e))
      } else {
        return {}
      }
    },
    placeNameByLmGmbPlaceId(state, getters) {
      return state.placeNameByLmGmbPlaceId
    },
    dashboardPlaceIds(state, getters) {
      return state?.placeIds
    },
    isMonoPlace(state, getters) {
      return state.placeIds && state.placeIds?.length === 1
    },
    hasAccessToSomeDashboardPlacesOnly(state, getters) {
      return (
        state.placeIds &&
        state.placeIds?.length > 1 &&
        getters.networkRankingPlaceIds.length !==
          getters.dashboardPlaceIds.length
      )
    },
    hasAccessToAllDashboardPlaces(state, getters) {
      return (
        getters.networkRankingPlaceIds.length ===
        getters.dashboardPlaceIds.length
      )
    },
    defaultRanking(state, getters) {
      if (getters.rankingPlacesVisibilityRestricted) {
        return 'mine'
      } else {
        return (getters.isMonoPlace ? (getters.groupRankingName ? 'group' : 'network') : 'mine')
      }
    },
    hasGroupRanking(state, getters) {
      return !!(state.groupRanking?.name && !getters.hasAccessToAllDashboardPlaces && getters.groupRankingPlaceIds?.length != getters.networkRankingPlaceIds?.length)
    },
    groupRankingName(state) {
      return state.groupRanking?.name
    },
    groupRankingPlaceIds(state) {
      return state.groupRanking?.placeIds
    },
    networkRankingName(state, getters) {
      if (state.networkRanking?.name && state.networkRanking?.name !== "") {
        return state.networkRanking?.name
      } else if (getters.currentLexicon.translate('network', 'network') && getters.currentLexicon.translate('network', 'network') !== "") {
        return getters.currentLexicon.translate('network', 'network')
      } else {
        return i18n.t('network')
      }
    },
    networkRankingPlaceIds(state) {
      return state.networkRanking?.placeIds
    },
    isNetworkMonoPlace(state, getters) {
      return getters.networkRankingPlaceIds?.length == 1
    },
    scopeIcon(state) {
      return state.icon
    },
    scopeLabel(state) {
      return state.label
    }
  },
  mutations: {
    setDashboardScopeFilters(state, scope) {
      state.selectedScope = (scope.selectedScope?.id ? scope.selectedScope : { id: 'all' })
      state.placeIds = scope.placeIds
      state.placeCampaignIds = scope.placeCampaignIds
      state.campaignIds = scope.campaignIds
      state.placeNameByLmGmbPlaceId = scope.placeNameByLmGmbPlaceId
      state.groupRanking = scope.groupRanking
      state.networkRanking = scope.networkRanking
    },
    setIconAndLabel(state, { icon, label}) {
      state.icon = icon
      state.label = label
    }
  },
  actions: {
    async setDashboardScopeFilters({ dispatch }, filters) {
      if (filters.scope) {
        await dispatch('updateScope', filters.scope)
      }
    },
    async updateScope({ commit, getters }, scope) {
      const placeId = (scope?.id?.match(/place-([0-9]+)/) || [])[1]
      const groupId = (scope?.id?.match(/group-([0-9]+)/) || [])[1]

      const placeCampaigns = await getFilteredPlaceCampaigns(getters, scope, placeId, groupId)

      const placeIds = _uniq(placeCampaigns.map((e) => e.placeId))
      const campaignIds = _uniq(placeCampaigns.map((e) => e.campaignId))
      const placeCampaignIds = _uniq(placeCampaigns.map((e) => e.id))
      const placeNameByLmGmbPlaceId = placeCampaigns.filter(pc => pc.placesLmGmbPlaceId).reduce((h, pc) => { h[pc.placesLmGmbPlaceId] = pc.placesName ; return h }, {})
      const smartRankings = await getSmartRankings(getters, placeCampaignIds)

      if (!getters.scopeLabel || !getters.scopeIcon) {
        const placeName = (!groupId && placeCampaigns?.length == 1 ? placeCampaigns[0]?.placesName : null)

        commit('setIconAndLabel', {
          label: (await fetchScopeLabel(getters, placeName, groupId, placeIds.length)),
          icon: (await fetchScopeIcon(placeName, groupId))
        })
      }

      commit('setDashboardScopeFilters', {
        selectedScope: scope,
        placeIds: placeIds,
        campaignIds: campaignIds,
        placeCampaignIds: placeCampaignIds,
        placeNameByLmGmbPlaceId: placeNameByLmGmbPlaceId,
        groupRanking: smartRankings.groupRanking,
        networkRanking: smartRankings.networkRanking
      })

      return placeCampaignIds
    }
  },
}

async function fetchScopeLabel(getters, placeName, groupId, nbPlace) {
  if (placeName) {
    return placeName
  } else if (groupId) {
    const request = new BasedQuery().select({ groups: ['name'] }).where({ id: groupId })
    const scopeLabel = (await request.resolve('fetchScopeLabel')).first()?.name

    return `${scopeLabel} (${nbPlace})`
  } else {
    return `${i18n.t('scopeFilter.my_perimeter')} - ${getters.currentLexicon.translate('network', 'network')}`
  }
}

async function fetchScopeIcon(placeName, groupId) {
  if (placeName) {
    return 'map-marker'
  } else if (groupId) {
    return 'map-markers'
  } else {
    return "network"
  }
}

async function getFilteredPlaceCampaigns(getters, scope, placeId, groupId) {
  let request = new BasedQuery().select({
    place_campaigns: ['id', 'campaign_id', 'place_id', 'places_lm_gmb_place_id', 'places_name' ],
  }).where({
    campaign_dashboards_dashboard_id: getters.currentDashboard.id,
    campaign_dashboards_dashboard_type: getters.currentDashboard.type,
  })

  if (placeId) {
    request = request.where({
      place_id: placeId,
    })
  }

  if (groupId) {
    request = request.where({
      place_groups_group_id: groupId
    })
  }

  const result = await request.resolve("getFilteredPlaceCampaigns")
  return result.data.placeCampaigns
}

async function getSmartRankings(getters, placeCampaignIds) {
  const resquest = getters.currentDashboardRequest
    .select({
      [getters.currentDashboardBasedTable]: [
        {
          smart_rankings: {
            params: { place_campaign_ids: placeCampaignIds },
          },
        },
      ],
    })

  const smartRankings = (await resquest.resolve("getSmartRankings")).first().smartRankings

  return smartRankings
}

export default scope

<template>
  <div>
    <div v-if="loading">
      <v-progress-linear
        indeterminate
        color="blue darken-2"
      />
    </div>
    <div
      v-if="!loading"
      class="headers d-flex fs-11 text-uppercase c-lightgrey align-center"
    >
      <div class="brand d-flex flex-row flex-grow-1 ml-3 mr-2">
        <div v-if="zoomable" class="action-spacer" />
        <div class="rank-spacer ml-3" />
        <div
          v-if="isNational || (isLocal && isMonoPlace)"
          class="ml-2 logo-spacer d-none d-sm-flex"
        />
        <span class="ml-2">{{ $t('lm_place') }}</span>
      </div>
      <div
        v-if="isLocal && !isMonoPlace"
        class="local-rank flex-shrink-0 d-none d-sm-flex"
      >
        <span>{{ $t('rankingTableHeaders.localRank') }}</span>
      </div>
      <div class="avg-score flex-shrink-0">
        <span
          v-if="true == false"
        >
          {{ $t('rankingTableHeaders.avgScore') }}
        </span>
      </div>

      <div
        v-if="isLocal && !isMonoPlace"
        class="top-competitor flex-shrink-0 d-none d-md-flex mr-2"
      >
        <span>{{ $t('rankingTableHeaders.topCompetitor') }}</span>
      </div>
      <div
        v-if="isLocal && !isMonoPlace"
        class="local-rank-link flex-shrink-0"
      />
    </div>

    <v-virtual-scroll
      class="scroll-section"
      :height="(ranking?.length || 0) * 60"
      item-height="60"
      :items="ranking"
      bench="10"
    >
      <template v-slot:default="{ item, index }">
        <div
          class="item d-flex flex-row"
          :class="rowClasses(item)"
          @click="onRowClick(item)"
        >
          <div
            class="brand d-flex flex-row align-center flex-grow-1 text-truncate ml-3"
          >
            <w-zoom-button
              v-if="zoomable"
              :highlighted="item.highlighted"
              :tooltip="zoomButtonTooltip(item)"
              @click="onRowClick(item)"
            />
            <w-rank
              class="ml-3"
              :rank="item.rank"
              :infoIconColor="notRankedInfoColor(item.rankStatus)"
              :nrTooltipText="nrTooltipText(item)"
              :infoLink="urlToBenchmark"
            />
            <img
              v-if="isNational || (isLocal && isMonoPlace)"
              class="ml-2"
              style="max-height: 36px; max-width: 36px;"
              :src="item.logoUrl"
            />
            <div class="ml-2 d-flex flex-column truncate align-self-center">
              <div class="name d-flex flex-row">
                <span class="f-14 c-darkergrey text-truncate">
                  {{item.name}}
                </span>
                <w-color-sign
                  v-if="displayEvolution"
                  class="ml-1 f-14"
                  :value="item.rankEvolution"
                />
              </div>
              <div
                v-if="item.nbPlaces && isNational"
                class="d-flex flex-row f-12 c-lightgrey sub-text"
              >
                <span>
                  {{ translateNbPlace(item) }}
                </span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span>
                      <a
                        :href="urlToBenchmark"
                        target='_blank'
                      >
                        <v-icon
                          v-if="item.rankStatus === 'not_fully_scraped'"
                          v-bind="attrs"
                          v-on="on"
                          class="f-12 c-yellow-darken tooltip ml-1"
                          small
                        >
                          mdi-information-outline
                        </v-icon>
                      </a>
                    </span>
                  </template>
                  <span>{{ item.rankMessage }}</span>
                </v-tooltip>
              </div>
              <div
                v-if="isLocal && isMonoPlace"
                class="d-flex flex-row text-truncate sub-text f-12 c-lightgrey"
              >
                <span class="text-truncate">
                  {{item.vicinity}}
                </span>
                <a
                  v-if="item.link"
                  :href="item.link"
                  class="link"
                  target="_blank"
                >
                  <v-icon
                    class="ml-1 f-12 c-lightgrey"
                    small
                  >
                    mdi-open-in-new
                  </v-icon>
                </a>
              </div>
            </div>
          </div>

          <div
            v-if="isLocal && !isMonoPlace"
            class="local-rank flex-shrink-0 align-self-center d-none d-sm-flex ml-3"
          >
            <span
              class="c-primary fb-14"
              v-html="relativeRank(item)"
            />
            <v-tooltip
              v-if="!item.relativeRank"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="f-12 c-lightgrey ml-1 local-rank_icon"
                  small
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ item.rankMessage }}</span>
            </v-tooltip>
            <w-color-sign
              v-if="displayEvolution && item.relativeRankEvolution"
              class="ml-1 f-14"
              :value="item.relativeRankEvolution"
            />
          </div>

          <div
            class="avg-score d-flex flex-column flex-shrink-0 align-self-center"
            v-if="item"
          >
            <WStarsAndReviews
              :score="item.avgScore"
              :scoreEvolution="item.avgScoreEvolution"
              :nbReview="item.nbReview"
              :nbReviewEvolution="item.nbReviewEvolution"
            />
          </div>

          <div
            class="top-competitor align-self-center flex-shrink-0 text-truncate d-none d-md-flex mr-2"
            v-if="isLocal && !isMonoPlace"
          >
            <div
              v-if="item.topCompetitorName"
              class="d-flex flex-column truncate"
            >
              <span
                class="name text-truncate"
              >
                {{ item.topCompetitorName }}
              </span>
              <div
                class="d-flex flex-row text-truncate sub-text f-12 c-lightgrey nowrap"
              >
                <span
                  class="text-truncate"
                  v-html="translateTopCompetitorInfo(item)"
                />
                <a
                  :href="item.topCompetitorLink"
                  target="_blank"
                >
                  <v-icon
                    class="ml-1 f-12 c-lightgrey"
                    small
                  >
                    mdi-open-in-new
                  </v-icon>
                </a>
              </div>
            </div>
          </div>

          <div
            class="local-rank-link flex-shrink-0 align-self-center"
            v-if="isLocal && !isMonoPlace"
          >
            <a v-on:click="displayLocalRank(item.id)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="c-lightgrey"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    mdi-format-list-numbered
                  </v-icon>
                </template>
                <span>{{ $t('localRankingTooltip') }}</span>
              </v-tooltip>
            </a>
          </div>
        </div>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'

  export default {
    name: "WidgetRankingTable",
    components: {
    },
    data() {
      return {
        urlToBenchmark: 'https://wizville.zendesk.com/hc/fr/articles/7909896871196-Benchmark-Avis-Google-R%C3%A8gles-de-collecte-des-avis'
      }
    },
    props: {
      ranking: {
        required: false,
        type: Array,
        default: () => []
      },
      mode: {
        required: true,
        type: String
      },
      displayEvolution: {
        required: false,
        type: Boolean,
        default: true
      },
      isMonoPlace: {
        required: true,
        type: Boolean
      },
      loading: {
        required: false,
        type: Boolean,
        default: false
      },
      zoomable: {
        required: false,
        type: Boolean,
        default: false
      },
      currentItem: {
        required: true
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboard',
        'currentLexicon',
        'lmGmbPlaceIdsScope'
      ]),
      isLocal() {
        return this.mode === 'local';
      },
      isNational() {
        return this.mode === 'national';
      }
    },
    methods: {
      zoomButtonTooltip(item) {
        if (this.currentItem?.type === 'lmPlace') {
          if (this.lmGmbPlaceIdsScope.includes(item.id) ||
              item.id === this.currentItem?.id) {
            return undefined
          }
        } else {
          if (this.currentDashboard.lmBrandIds.includes(item.id) ||
            item.id === this.currentItem?.id) {
            return undefined
          }
        }

        return this.$t('zoom_on_competitor')
      },
      rowClasses(item) {
        return {
          'bg-lightcyan': item.highlighted,
          'pointer': this.zoomable
        }
      },
      onRowClick(item) {
        if (this.zoomable) {
          this.$emit('onRowClick', item)
        }
      },
      nrTooltipText(item) {
        if (item.rankMessage) {
          return item.rankMessage
        } else {
          return item.nbReview > 0 ? this.$t('notEnoughReviewsTooltip',
            { minimalNumberOfFeedbackForRanking: this.currentDashboard.minimalNumberOfFeedbackForRanking }
          ) : this.$t('notEnoughReviewsTooltipWhenNotReviews')
        }
      },
      async displayLocalRank(lmPlaceId) {
        const request = this.$basedRequest().select({ place_campaigns: [
          { MAX_places_name: { as: 'placeName' } },
          { MAX_places_id: { as: 'placeId' } }
        ] }).where({
          places_lm_gmb_place_id: lmPlaceId,
          campaign_dashboards_dashboard_id: this.currentDashboard.id,
          campaign_dashboards_dashboard_type: this.currentDashboard.type
        })

        const placeCampaign = (await this.$resolve(request)).first()

        if (placeCampaign.placeId) {
          this.$store.commit('setIconAndLabel', {
            label: placeCampaign.placeName,
            icon: "map-marker"
          })
          this.$store.dispatch('updateDashboardFilters', {
            scope: {
              icon: "map-marker",
              id: `place-${placeCampaign.placeId}`,
              text: placeCampaign.placeName
            }
          })
        }
      },
      notRankedInfoColor(rankStatus) {
        return  ['not_scraped'].includes(rankStatus) ? 'c-yellow-darken' : 'c-lightgrey'
      },
      translateNbReview(item) {
        return this.$t('nbReview_html', {
          nbReview: item?.nbReview?.toLocaleString(),
        })
      },
      translateTopCompetitorInfo(item) {
        return item.topCompetitor ? this.$t('topCompetitor_html', {
          nbReview: item.topCompetitorNbReview.toLocaleString(),
          avgScore: item.topCompetitorAvgScore.toFixed(2)
        }) : '';
      },
      translateNbPlace(item) {
        return item.nbPlaces > 1 ?
          `${item.nbPlaces} ${this.$t('lm_places')}` :
          `${item.nbPlaces} ${this.$t('lm_place')}`
      },
      relativeRank(item) {
        const outOf = this.$options.filters.ordinal(item.relativeRank);
        return item.relativeRank ?
          this.$t('relativeRank_html', { relativeRank: item.relativeRank, outOf: outOf, relativeRankOn: item.relativeRankOn }) :
          this.$t('nc');
      }
    }
  }
</script>

<style lang='stylus' scoped>
  @import '~@theme/medias.styl'

  .local-rank
    width: 90px

  .avg-score
    width: 190px
    +forBreakpoints(mobile)
      width: 100px

  .top-competitor
    width: 150px

  .local-rank-link
    width: 30px

  .rank-spacer, .logo-spacer
    width: 36px

  .action-spacer
    width: 28px

  .w-rating
    line-height: 0px

  .name
    line-height: 15px

  .item
    border-bottom: thin solid rgba(0,0,0,.12)
    height: 60px

  .headers .col
    height: 40px
    border-bottom: none

  .headers
    border-bottom: thin solid rgba(0,0,0,.12)
    height: 40px

  .text-truncate
    max-width: 100%

  .truncate
    min-width: 0%

  .sub-text
    line-height: 15px

  .v-icon
    bottom: 1px

  .scroll-section
    background:
      linear-gradient(white 30%, rgba(255,255,255,0)),
      linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 6px, 100% 6px;
    background-attachment: local, local, scroll, scroll;

  .local-rank_icon
    position: relative
    top: 0.5px
</style>

<template>
  <WStatsWidget
    class="widget-position"
    :title="title"
    :cols="12"
    height="200px"
    contentWidth="100%"
    :loading="loading"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #options>
      <WidgetSettingsMenu
        v-if="showWidgetSettingsMenu"
        :mode="mode"
        :scope="scope"
        :periodType="periodType"
        :currentItem="currentItem"
        :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreferenceId"
      >
      </WidgetSettingsMenu>
    </template>

    <template #content>
      <div class="text-center">
        <w-position-widget
          :position="ranking && rank"
          :evolution="ranking && rankEvolution"
          :displayEvolution="displayEvolution"
          :nrTooltipText="rankMessage"
          :iconInfoColor="getIconInfoColor"
        />
      </div>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetSettingsMenu from '../WidgetSettingsMenu'
  import HeaderSubtitle from '../shared/HeaderSubtitle'

  export default {
    name: "WidgetPosition",
    components: {
      WidgetSettingsMenu,
      HeaderSubtitle
    },
    props: {
      mode: {
        required: true,
        type: String
      },
      scope: {
        required: false,
        type: String
      },
      periodType: {
        required: false,
        type: String
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      },
      currentItem: {
        required: true
      },
      ranking: {
        required: true
      },
      loading: {
        required: false
      },
      showWidgetSettingsMenu: {
        required: false,
        default: true
      }
    },
    computed: {
      ...mapGetters([
        "isMonoPlace",
        "dashboardFilterDates",
        "datesScope"
      ]),
      currentItemRanking() {
        if (this.loading) return undefined

        return this.ranking.find(item => {
          return item.id === Number(this.currentItem.id) ||
            (this.currentItem?.isUserItem && item.id === -1)
        })
      },
      rank() {
        return this.currentItemRanking?.rank
      },
      rankEvolution() {
        return this.currentItemRanking?.rankEvolution
      },
      rankMessage() {
        return this.currentItemRanking?.rankMessage
      },
      getIconInfoColor() {
        return ['not_scraped'].includes(this.currentItemRanking?.rankStatus) ? 'c-yellow-darken' : 'c-lightgrey'
      },
      displayEvolution() {
        return this.datesScope.key !== 'genesis';
      },
      title() {
        const localTitle = this.$t("competitors_widget_position_mono_title_local")

        if (this.mode === "local") {
          if (this.currentItem?.isUserItem === false) {
            return `${localTitle} - ${this.currentItem.name}`
          }

          return localTitle
        } else {
          return this.$t("competitors_widget_position_mono_title_national")
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'

  .widget-position-header
    height: $widget-header-height
    .widget-position-main-title
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
</style>

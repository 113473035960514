<template>
  <WStatsWidget
    class="expectation-topic-ranking"
    :title="title"
    :loading="loading"
    v-if="!fullScreenMode || (filteredRanking && filteredRanking.length > 0)"
    :empty="filteredRanking && filteredRanking.length == 0"
    :emptyLabel="$t('no_verbatim_topic')"
    :cols="12"
    :height="fullScreenMode ? undefined : '380px'"
    contentWidth="100%"
    :justifyCenter="false"
  >
    <template #title>
      <a v-if="activeRanking" @click="activeRanking = null">
        <v-icon small style="position: relative; bottom: 1px; padding-right: 5px">mdi-keyboard-backspace</v-icon>
        <span>{{ $t('competitors_widget_ranking_title') }} ({{ activeRanking.name }})</span>
      </a>

      <a v-else-if="activeVerbatim" @click="activeVerbatim = null">
        <v-icon small style="position: relative; bottom: 1px; padding-right: 5px">mdi-keyboard-backspace</v-icon>
        <span> {{ $t('reporting_verbatim')}} ({{ activeVerbatim.name }})</span>
      </a>

      <template v-else>
        <span class="pr-1">{{ title }}</span><WTooltip :message="$t('expectation_topic_ranking_tooltip')" />
      </template>
    </template>

    <template #subtitle>
      {{ dashboardFilterDates.text }}
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="f-12 c-lightgrey" small>mdi-information-outline</v-icon>
        </template>
        <span>{{ $t('reviewsNextMonthDisplayTooltip') }}</span>
      </v-tooltip>
    </template>

    <template #content>
      <ZoomedTopicRankingTable
        v-if="activeRanking"
        :topicRanking="activeRanking"
      />
      <ZoomedTopicVerbatimTable
        v-else-if="activeVerbatim"
        :scope="scope"
        :currentItem="currentItem"
        :topicId="activeVerbatim.id"
      />
      <ExpectationTopicRankingTable
        v-else
        @showRankingTable="showRankingTable"
        @showVerbatimTable="showVerbatimTable"
        :ranking="filteredRanking"
        :currentItem="currentItem"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ExpectationTopicRankingTable from './ExpectationTopicRankingTable'
  import ZoomedTopicRankingTable from "../ZoomedTopicRankingTable"
  import ZoomedTopicVerbatimTable from "../ZoomedTopicVerbatimTable"

  export default {
    name: "ExpectationTopicRanking",
    props: ['ranking', 'loading', 'currentItem', 'scope'],
    data() {
      return {
        activeRanking: null,
        activeVerbatim: null
      }
    },
    components: {
      ExpectationTopicRankingTable,
      ZoomedTopicRankingTable,
      ZoomedTopicVerbatimTable
    },
    computed: {
      ...mapGetters([
        'dashboardFilterDates',
        'fullScreenMode'
      ]),
      title() {
        return this.$t('customer_expectations_widget_title')
      },
      filteredRanking() {
        return this.ranking?.filter(item => item?.weight)
      }
    },
    methods: {
      showRankingTable(item) {
        this.activeRanking = item
      },
      showVerbatimTable(item) {
        this.activeVerbatim = item
      }
    }
  }
</script>

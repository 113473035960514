export const voterAvgScoreRepartitions = {
  detractors5: { min: 0, max: 3.5, condition: '[..[', as: 'nbDetractors' },
  neutrals5: { min: 3.5, max: 4.5, condition: '[..[', as: 'nbNeutrals' },
  promoters5: { min: 4.5, max: 5, condition: '[..]', as: 'nbPromoters' },
  detractors10: { min: 0, max: 6.5, condition: '[..[', as: 'nbDetractors' },
  neutrals10: { min: 6.5, max: 8.5, condition: '[..[', as: 'nbNeutrals' },
  promoters10: { min: 8.5, max: 10, condition: '[..]', as: 'nbPromoters' },
}

export const voterNpsRepartitions = {
  detractors: { min: 0, max: 7, condition: '[..[', as: 'nbDetractors' },
  neutrals: { min: 7, max: 9, condition: '[..[', as: 'nbNeutrals' },
  promoters: { min: 9, max: 10, condition: '[..]', as: 'nbPromoters' },
}

<template>
  <WStatsWidget
    :cols="12"
    :empty="empty"
    :justifyCenter="false"
    :loading="loading"
    :subtitle="displayedSubtitle"
    class="topics-verbatim-themes-widget"
    contentWidth="100%"
    minHeight="200px"
    maxHeight="400px"
  >
    <template #options v-if="!loading">
      <div class="d-flex align-center">
        <v-select
          v-model="groupBy"
          :items="groupByOptions"
          hide-details
          dense
          class="pt-0 mt-0"
          style="width: 80px"
        />
        <v-btn :title="$t('export_verbatim_by_theme')" v-if="!exportLoading" icon @click="downloadExportData"><v-icon small>mdi-download</v-icon></v-btn>
        <v-btn v-else icon><v-icon small>mdi-download-off</v-icon></v-btn>
      </div>
    </template>

    <template #title>
      <div class="d-flex">
        <a v-if="zoomedTopic" @click="zoomedTopic = null">
          <v-icon small style="position: relative; bottom: 1px; padding-right: 5px">mdi-keyboard-backspace</v-icon>
          <span>{{ zoomedTopic.name }}</span>
        </a>
        <a v-else-if="zoomedTheme" @click="zoomedTheme = null">
          <v-icon small style="position: relative; bottom: 1px; padding-right: 5px">mdi-keyboard-backspace</v-icon>
          <span>{{ zoomedTheme.name }}</span>
        </a>
        <span v-else>{{ $t(title) }}</span>
      </div>
    </template>

    <template #content>
      <ThemesTable class="pb-1"
        v-if="!loading"
        @zoomOnTopic="zoomOnTopic"
        @zoomOnTheme="zoomOnTheme"
        :campaign="campaign"
        :topics="formatedTopics"
        :themeById="themeById"
        :loading="loading"
        :zoomedData="zoomedData"
        :groupBy="groupBy"
      />
      <v-skeleton-loader v-else type="paragraph" class="my-3" />
    </template>

    <template #footer-right>
      <span v-if="exportLoading">
        {{ exportProgression }}
        <v-icon small @click="exportLoading = false" color="error" class="ml-1">mdi-close</v-icon>
      </span>
    </template>
  </WStatsWidget>
</template>

<script>
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import ThemesTable from './ThemesTable'
  import dayjs from "dayjs"
  import {mapGetters} from "vuex";
  import exportToExcel from '@shared/helpers/export-to-excel.js'

  export default {
    name: "TopicsVerbatimThemesWidget",
    props: ['campaign', 'formatedTopics', 'themeById', 'loading', 'zoomedData'],
    mixins: [
      WidgetMixin
    ],
    components: {
      ThemesTable
    },
    data() {
      return {
        empty: false,
        title: this.$t('topics_verbatim_themes_widget_title'),
        zoomedTheme: null,
        zoomedTopic: null,
        groupBy: "themes",
        exportLoading: false,
        exportProgression: null,
        dateSort: "desc",
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
        'currentDashboardParams',
        'hasRightClassifyVerbatims',
        'currentLexicon'
      ]),
      baseRequest() {
        let request = this.dashboardFilterRequest.where({ campaign_id: this.campaign.id})

        request = request.order(['MAX_voters_vote_at', this.dateSort])
        return request
      },
      zoomed() {
        if (this.zoomedTheme) {
          return this.zoomedTheme.name
        } else if (this.zoomedTopic) {
          return this.zoomedTopic.name
        }
      },
      groupByOptions() {
        return [ { text: this.$t('themes'), value: 'themes' }, { text: this.$t('topics'), value: 'topics' } ]
      },
      hasTextAnalysisEnabled() {
        return this.campaign?.hasTextAnalysisEnabled || this.mainCampaign?.hasTextAnalysisEnabled
      },
      topicIdColumn() {
        return this.hasTextAnalysisEnabled ? 'text_analysis_topics_id' : 'topic_id'
      },
      themeIdColumn() {
        return this.hasTextAnalysisEnabled ? 'text_analysis_themes_id' : 'topic_semantics_id'
      },
      topicPublicNameColumn() {
        return this.hasTextAnalysisEnabled ? 'topic_public_name' : 'topics_name'
      },
    },
    methods: {
      zoomOnTopic(topic) {
        this.$router.push({ params: { sectionId: `topic${topic.id}` } })
      },
      zoomOnTheme(theme) {
        this.$router.push({ params: { sectionId: `theme${theme.id}` } })
      },
      async fetchVerbatimsTotal(baseRequest) {
        let request = baseRequest.select({
          main_topics_verbatims: [
            { 'COUNT_id': { as: 'total' } }
          ]
        })

        const result = (await this.$resolve(request)).first()

        return result.total
      },
      async downloadExportData() {
        this.constructHeaderColumns()

        this.exportLoading = true
        let offset = 0;
        const shard = 100;
        const totalVerbatim = await this.fetchVerbatimsTotal(this.baseRequest);
        let allVerbatims = [];
        let lastVerbatimFetched = false;
        this.exportProgression = '0%'
        while (lastVerbatimFetched === false) {
          const verbatims = await this.fetchVerbatims(this.baseRequest, offset, shard)
          if (Object.values(verbatims).length === 0) {
            lastVerbatimFetched = true
          } else {
            allVerbatims = [...allVerbatims,  ...verbatims ]
            offset += shard
          }

          if (!this.exportLoading) {
            return
          } else {
            this.exportProgression = `${allVerbatims.length} / ${totalVerbatim} (${Math.round(allVerbatims.length * 100 / totalVerbatim)}%)`
          }
        }

        this.verbatimFormattedData(Object.values(allVerbatims))
        this.exportProgression = null
        this.exportLoading = false
      },
      constructHeaderColumns() {
        const themeTopicsData = this.themeById;
        let themeIds = Object.keys(themeTopicsData);
        let themeTopics = []
        themeIds.map((themeId) => {
          const theme = themeTopicsData[themeId]
          const themeWithTopics = theme.topics.map((topic) => {
            return {
              name: topic.topic.name,
              id: topic.topic.id
            }
          })
          themeTopics.push({
            themeId: theme.id,
            themeName: theme.name,
            topics: themeWithTopics
          })
        })
        return themeTopics
      },
      async fetchVerbatims(baseRequest, offset, perPage) {
        let filteredTopicsVerbatimRequest = baseRequest.select({
          main_topics_verbatims: [
            { 'MAX_feeling': { as: 'feeling' } },
            { [`ARRAY_AGG_${this.topicIdColumn}`]: { as: 'topicId' } },
            { [`ARRAY_AGG_${this.themeIdColumn}`]: { as: 'themeId' } },
            { [`ARRAY_AGG_${this.topicPublicNameColumn}`]: { as: 'topicName' } },
            { "MAX_voter_token": { as: 'voterUniqueId' } },
            { "MAX_verbatims_content": { as: 'content' } },
            { "MAX_verbatims_id": { as: 'verbatimId' } },
            { "MAX_voters_vote_at": { as: 'reviewDate' } },
            { "MAX_voters_avg_score": { as: 'avgScore' } },
            { "MAX_voters_nps": { as: 'nps' } },
            { "MAX_verbatims_text_analysis_processed_at": { as: 'verbatimTextAnalysisProcessedAt' } },
            { "COUNT_topics_verbatim_reclassifications_id": { as: 'topicsVerbatimReclassificationsCount' } },
            { "MAX_voter_author":  { as: 'voterAuthor'} },
            { "MAX_voter_email":  { as: 'voterEmail'} },
            { "MAX_place_name":  { as: 'placeName'} },
            { "ARRAY_AGG_place_categories":  { as: 'placeCategories'} },
          ]
        }).limit(
            perPage
        ).offset(
            offset
        ).group([
          'verbatim_id',
          this.themeIdColumn,
        ])

        let resultFilteredTopicsVerbatim = (await this.$resolve(filteredTopicsVerbatimRequest)).data

        let verbatimIds = Object.keys(resultFilteredTopicsVerbatim)
        let allTopicsVerbatimForVerbatims = this.$basedRequest().select({
          main_topics_verbatims: [
            { 'MAX_feeling': { as: 'feeling' } },
            { [`ARRAY_AGG_${this.topicIdColumn}`]: { as: 'topicId' } },
            { [`ARRAY_AGG_${this.themeIdColumn}`]: { as: 'themeId' } },
            { [`ARRAY_AGG_${this.topicPublicNameColumn}`]: { as: 'topicName' } },
            { "MAX_voter_token": { as: 'voterUniqueId' } },
            { "MAX_voters_vote_at": { as: 'voteAt' } },
            { "MAX_verbatims_content": { as: 'content' } },
            { "MAX_verbatims_id": { as: 'verbatimId' } },
            { "MAX_voters_vote_at": { as: 'reviewDate' } },
            { "MAX_voters_avg_score": { as: 'avgScore' } },
            { "MAX_voters_nps": { as: 'nps' } },
            { "MAX_verbatims_text_analysis_processed_at": { as: 'verbatimTextAnalysisProcessedAt' } },
            { "COUNT_topics_verbatim_reclassifications_id": { as: 'topicsVerbatimReclassificationsCount' } },
            { "MAX_voter_author":  { as: 'voterAuthor'} },
            { "MAX_voter_email":  { as: 'voterEmail'} },
            { "MAX_place_name":  { as: 'placeName'} },
            { "ARRAY_AGG_place_categories":  { as: 'placeCategories'} },
          ]
        }).where({
          verbatim_id: verbatimIds
        }).group([
          'verbatim_id',
          this.themeIdColumn,
        ])

        let resultAllTopicsVerbatimForVerbatims = (await this.$resolve(allTopicsVerbatimForVerbatims)).data
        for (const [verbatimId, topicsVerbatims] of Object.entries(resultAllTopicsVerbatimForVerbatims)) {
          for (const [topicId, topicsVerbatim] of Object.entries(topicsVerbatims)) {
            topicsVerbatim['hide'] = true
          }
          resultAllTopicsVerbatimForVerbatims[verbatimId] = { ...topicsVerbatims, ...resultFilteredTopicsVerbatim[verbatimId] }
        }
        return Object.values(resultAllTopicsVerbatimForVerbatims)
      },
      topicsColumnValue(verbatim) {
        const verbatimIds = Object.values(
            verbatim.themeId.reduce((o, a) => { o[a.id] = a; return o}, {}));
        const topicIds = Object.values(
            verbatim.topicId.reduce((o, a) => { o[a.id] = a; return o}, {}));

        let topics = []
        verbatimIds.map((themeId) => {
          const theme = this.themeById[themeId]
          if (typeof theme !== 'undefined') {
            theme.topics.map((themeTopic, index) => {
              if (topicIds.includes(themeTopic.topic.id)){
                if (!topics.includes(themeTopic.topic.name)) {
                  topics.push(themeTopic.topic.name)
                }
              }
            })
          } else {
            console.error(`Theme ${themeId} not found for verbatim ${verbatim.verbatimId}`)
          }
        })
        return topics.join('| ')
      },
      topicColumnValue(verbatim, topicId) {
        let themeIds = verbatim.themeId.filter((value, index, self) => self.indexOf(value) === index)
        let columnValue = ""

        themeIds.map((themeId) => {
          const theme = this.themeById[themeId]
          theme.topics.map((themeTopic) => {
            if (topicId === themeTopic.topic.id){
              switch (verbatim.feeling) {
                case 2:
                  columnValue += ' NEG '
                  break;
                case 1:
                  columnValue += ' NEU '
                  break;
                default:
                  columnValue += ' POS '
              }
            }
          })
        })
        return columnValue
      },
      excelHeaders() {
        let additionnalHeaders = this.constructHeaderColumns()
        let themeTopicHeads = []
        additionnalHeaders.map((addHeader) => (
            addHeader.topics.map((hd) => themeTopicHeads.push( addHeader.themeName + " > " + hd.name) )
        ))

        return [
          this.$t("campagn_name"),
          this.$t("token"),
          this.$t("automated_message_treatments_voter_author"),
          this.$t("email"),
          this.$t("components.dateFilter.types.review"),
          this.currentLexicon.translate('place', 'place'),
          this.$t("categories"),
          this.$t("note"),
          this.$t("components.dashboardPage.sidebar.verbatim.title"),
          this.$t("feelings"),
          ...themeTopicHeads
        ]
      },
      verbatimFormattedData(vData) {
        const excelHeadersColumns = this.excelHeaders()
        let additionnalHeaders = this.constructHeaderColumns()
        const rows = vData.map(verbatimWithKey => {
          let themeTopicRow = []
          const themeIds = Object.keys(verbatimWithKey)
          const themeId = Object.keys(verbatimWithKey)[0];
          const verbatim = verbatimWithKey[themeId]
          let topicIds = []
          let evolvedSubjects = ''
          themeIds.map((themId, index) => {
            const topId = Object.values(
                verbatimWithKey[themId].topicId.reduce((o, a) => { o[a.id] = a; return o}, {}));
            topicIds.push(...topId)
            if (index > 0) {
              evolvedSubjects += ' | '
            }
            let lVerbatim = verbatimWithKey[themId]
            let evolvedSubject = this.topicsColumnValue(lVerbatim)
            evolvedSubjects += evolvedSubject[0].toUpperCase() + evolvedSubject.slice(1)
          })
          let note = verbatim.avgScore;
          if (note === 'nps_score') {
            note = verbatim.nps;
          }
          additionnalHeaders.map((cellValue) => {
            cellValue.topics.map((hd) => {
              if (topicIds.includes(hd.id)) {
                topicIds.map((topicId) => {
                  if (parseInt(hd.id) === parseInt(topicId)) {
                    let verbatimValue = verbatimWithKey[cellValue.themeId]
                    themeTopicRow.push(hd.name[0].toUpperCase() + hd.name.slice(1)  + ' ' + this.topicColumnValue(verbatimValue, hd.id))
                  }
                })
              } else {
                themeTopicRow.push('')
              }
            })
          })
          return [
            this.campaign.name,
            verbatim.voterUniqueId,
            verbatim.voterAuthor,
            verbatim.voterEmail,
            dayjs(String(verbatim.reviewDate)).format('DD/MM/YYYY'),
            verbatim.placeName,
            verbatim.placeCategories.join(','),
            note,
            verbatim.content,
            evolvedSubjects,
            ...themeTopicRow,
          ]
        })
        exportToExcel(
            `Export_${this.$t("topics")}_${this.campaign.name}`,
            this.$t("reporting_verbatim"),
            rows,
            excelHeadersColumns
        )
      }
    }
  }
</script>

  <template>
  <div v-if="currentDashboard.preferences.isLrmEligible" class="lm-places pl-3">
    <WLateralSectionLayout :fullScreenMode="fullScreenMode">
      <template #lateral-section>
        <template>
          <WidgetPosition
            v-if="isMonoPlace"
            :mode="mode"
            :scope="scope"
            :periodType="periodType"
            :ranking="ranking"
            :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference?.id"
            :lmBrandIds="campaignSmartBenchmarkPreference?.lmBrandIds"
            :key="`widget-position-${currentItem?.id}-${dashboardFilterBase64}`"
            :currentItem="currentItem"
            :loading="rankingLoading"
          />

          <WidgetScore
            :mode="mode"
            :scope="scope"
            :periodType="periodType"
            :ranking="ranking"
            :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference?.id"
            :key="`widget-score-${currentItem?.id}-${dashboardFilterBase64}`"
            :currentItem="currentItem"
            :loading="rankingLoading"
          />
        </template>
      </template>

      <template #main-section>
        <template>
          <WidgetRanking
            :mode="mode"
            :scope="scope"
            :periodType="periodType"
            :ranking="ranking"
            :campaignSmartBenchmarkPreference="campaignSmartBenchmarkPreference"
            :lmBrandIds="campaignSmartBenchmarkPreference?.lmBrandIds"
            :currentItem="currentItem"
            hideHeader
            :key="`widget-ranking-${dashboardFilterBase64}`"
            :zoomable="widgetRankingZoomable"
            :loading="rankingLoading"
          />

          <VerbatimTopicRankings
            v-if="showVerbatimTopicRankings"
            :scope="scope"
            :currentItem="currentItem"
            :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference.id"
            :key="`verbatim-topic-rankings-${currentItem.id}-${dashboardFilterBase64}`"
          />

          <widget-evolution
            :mode="mode"
            :scope="scope"
            :periodType="periodType"
            :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference?.id"
            :lmBrandIds="campaignSmartBenchmarkPreference?.lmBrandIds"
            :currentItem="currentItem"
            :key="`widget-evolution-${dashboardFilterBase64}`"
          />

          <widget-lm-place-review-table
            v-if="mode === 'local'"
            :key="`widget-lm-place-review-table-${currentItem?.id}-${dashboardFilterBase64}`"
            :currentItem="currentItem"
          />
        </template>
      </template>
    </WLateralSectionLayout>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetPosition from '../widgets/Position'
  import WidgetScore from '../widgets/Score'
  import WidgetRanking from '../widgets/Ranking'
  import WidgetEvolution from '../widgets/Evolution'
  import WidgetLmPlaceReviewTable from '../widgets/LmPlaceReviewTable'
  import VerbatimTopicRankings from '../widgets/VerbatimTopicRankings'

  export default {
    name: "LmPlaces",
    components: {
      WidgetPosition,
      WidgetScore,
      WidgetRanking,
      WidgetEvolution,
      WidgetLmPlaceReviewTable,
      VerbatimTopicRankings
    },
    data() {
      return {
        rankingDataLoading: true
      }
    },
    props: {
      mode: {
        required: true,
        type: String
      },
      scope: {
        required: true,
        type: String
      },
      periodType: {
        required: true,
        type: String,
      },
      currentItem: {
        required: true
      },
      campaignSmartBenchmarkPreference: {
        required: false
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboard",
        "placeIds",
        "placeIdsScope",
        "isMonoPlace",
        "lmGmbPlaceIdsScope",
        "dashboardFilterBase64",
        "fullScreenMode",
        "lrmDashboardFilterRequest",
        "datesScope",
        "currentDashboardBasedTable"
      ]),
      widgetRankingZoomable() {
        return (this.mode === 'local' && this.isMonoPlace) ||
          this.mode === 'national'
      },
      showVerbatimTopicRankings() {
        return this.currentItem &&
          this.campaignSmartBenchmarkPreference &&
          this.campaignSmartBenchmarkPreference.enabled &&
          ((this.currentItem.type === 'lmPlace' && this.isMonoPlace) || this.currentItem.type === 'lmBrand')
      },
      isLocal() {
        return this.mode === 'local';
      },
      isNational() {
        return this.mode === 'national';
      },
      ranking() {
        this.currentItem
        return this.rows?.map(row => {
          return this.mapping(row);
        }) || undefined
      },
      rankingLoading() {
        return this.rankingDataLoading || this.ranking === undefined
      }
    },
    asyncComputed: {
      rows: {
        async get() {
          this.rankingDataLoading = true

          const request = this.lrmDashboardFilterRequest.select({
            [this.currentDashboardBasedTable]: [{
              lrm_ranking: { params: {
                mode: this.mode,
                scope: this.scope,
                period: this.periodType,
                place_ids: this.placeIdsScope,
                date_begin: this.datesScope.dateBegin,
                date_end: this.datesScope.dateEnd,
                compared_date_begin: this.datesScope.comparedDateBegin,
                compared_date_end: this.datesScope.comparedDateEnd,
                competitor_lm_brand_ids: this.campaignSmartBenchmarkPreference?.lmBrandIds
              }}
            }]
          })

          const data = (await this.$resolve(request)).first()?.lrmRanking || []

          this.rankingDataLoading = false

          return data
        },
        default: null
      }
    },
    methods: {
      mapping(row) {
        const defaultMapping = {
          id: row.id,
          rank: row.rank,
          rankEvolution: row.rankEvolution,
          logoUrl: row.logoUrl,
          name: row.name,
          nbReview: row.nbReview,
          rankable: row.rankable,
          rankStatus: row.rankStatus,
          nbPlaces: row.nbPlaces,
          scrapedAt: row.scrapedAt,
          rankMessage: row.rankMessage,
          debugLmPlaceIdWithScrapedAt: row.debugLmPlaceIdWithScrapedAt,
          avgScore: row.avgScore,
          avgScoreCompared: row.avgScoreCompared,
          avgScoreEvolution: row.avgScoreEvolution,
          highlighted: row.id === this.currentItem?.id || (this.currentItem?.isUserItem && row.id === -1)
        }

        const localMapping = {
          vicinity: row.vicinity,
          link: row.link
        }

        const nationalMapping = {
          nbPlaces: row.nbPlaces
        }

        if (this.isLocal) {
          const mapping = { ...defaultMapping, ...localMapping }

          return this.isMonoPlace ? mapping : { ...mapping, ...{
            relativeRank: row.relativeRank,
            relativeRankEvolution: row.relativeRankEvolution,
            relativeRankOn: row.relativeRankOn,
            topCompetitor: row.topCompetitor,
            topCompetitorName: row.topCompetitor?.name,
            topCompetitorNbReview: row.topCompetitor?.nbReview,
            topCompetitorAvgScore: row.topCompetitor?.avgScore,
            topCompetitorLink: row.topCompetitor?.link,
          }}
        } else {
          return { ...defaultMapping, ...nationalMapping };
        }
      },
    }
  }
</script>

<template>
  <div>
    <w-drop-down-menu
      v-model="selectedValues"
      :items="items"
      icon="mdi-cog"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "WidgetSettingsMenu",
    props: {
      mode: {
        required: true,
        type: String
      },
      scope: {
        required: true,
        type: String
      },
      periodType: {
        required: true,
        type: String
      },
      currentItem: {
        required: true
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterDates'
      ]),
      selectedValues: {
        get() {
          return [
            this.periodType
          ]
        },
        set(val) {
          this.$router.push({
            name: "Competitions",
            params: {
              mode: this.mode,
              scope: this.scope,
              periodType: val[0],
              currentItemId: this.currentItem?.id || 'default',
              campaignSmartBenchmarkPreferenceId: this.campaignSmartBenchmarkPreferenceId
            }
          })
        }
      },
      items() {
        const items = [
          {
            title: this.$t('widget_settings_period_head_title'),
            help: this.$t('widget_settings_period_head_help'),
            children: [
              {
                title: this.$t('widget_settings_period_title', { period: this.dashboardFilterDates.text }),
                value: 'period'
              },
              {
                title: this.$t('widget_settings_genesis_title'),
                value: 'genesis'
              },
            ]
          }
        ]

        return items
      },
    },
  }
</script>

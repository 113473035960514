<template>
  <WStatsWidget
    :cols="12"
    :justifyCenter="false"
    :title="$t(title)"
    class="widget-evolution"
    contentPadding="0.75em"
    contentWidth="100%"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #options>
      <div class="d-flex">
        <WidgetSettingsMenu
          :mode="mode"
          :scope="scope"
          :periodType="periodType"
          :currentItem="currentItem"
          :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreferenceId"
        />
        <w-drop-down-menu
          v-show="!loading"
          data-html2canvas-ignore
          class="ml-auto"
          :items="exportOptions"
          icon="mdi-download"
        />
      </div>
    </template>

    <template #content>
      <WTimelineChart
        ref="timelineChart"
        class="pb-3"
        :key="requestObj['id']"
        :quarterOffset="currentUser.quarterOffset"
        @loadingStatusChanged="changeLoadingStatus"
        :defaultOptions="defaultOptions"
        :chartOptions="chartOptions"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _uniqueId from "lodash/uniqueId"
  import WidgetSettingsMenu from '../WidgetSettingsMenu'
  import HeaderSubtitle from '../shared/HeaderSubtitle'
  import ExportableChartMixin from '@statistics/shared/ChartsFeatures/exportable_chart_mixin'

  export default {
    name: "widget-evolution",
    components: {
      WidgetSettingsMenu,
      HeaderSubtitle
    },
    mixins: [
      ExportableChartMixin
    ],
    props: {
      mode: {
        required: true,
        type: String
      },
      scope: {
        required: true,
        type: String
      },
      periodType: {
        required: true,
        type: String
      },
      currentItem: {
        required: true
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      },
      lmBrandIds: {
        required: false
      }
    },
    data() {
      return {
        title: this.$t("competitors_widget_evolution_title"),
        loading: true,
        exportElementId: _uniqueId('evolution-')
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboardBasedTable",
        "lrmDashboardFilterRequest",
        "placeIdsScope",
        "datesScope",
        "dashboardFilterUnit",
        'currentDashboard',
        'dashboardFilterDates',
        'currentUser'
      ]),
      requestObj() {
        const lrmDashboardFilterRequest = this.lrmDashboardFilterRequest
        const placeIdsScope = this.placeIdsScope
        const periodType = this.periodType
        const datesScope = this.datesScope
        const mode = this.mode
        const scope = this.scope
        const lmBrandIds = this.lmBrandIds
        const currentDashboardBasedTable = this.currentDashboardBasedTable
        const uniqueId = Math.random().toString(36).substr(2, 9)
        const self = this

        const requestObj = {
          id: uniqueId,
          dateBegin: null,
          dateEnd: null,

          dateBetween(dateBegin, dateEnd) {
            this.dateBegin = dateBegin
            this.dateEnd = dateEnd
            return this
          },
          async resolve(queryName, { tickNumber, tickUnit, timeSerieParams }, endPoint) {
            const request = lrmDashboardFilterRequest.select({
              [currentDashboardBasedTable]: [{
                lrm_ranking_evolution: { params: {
                  mode: mode,
                  scope: scope,
                  period: periodType,
                  place_ids: placeIdsScope,
                  date_begin: this.dateBegin,
                  date_end: this.dateEnd,
                  ranking_date_begin: datesScope.dateBegin,
                  ranking_date_end: datesScope.dateEnd,
                  tick_unit: tickUnit,
                  competitor_lm_brand_ids: lmBrandIds
                } }
              }]
            })

            const result = (await self.$resolve(request)).first()
            return {
              data: result?.lrmRankingEvolution || {}
            }
          },
        };

        return Object.create(requestObj)
      },
      exportFilename() {
        const pageName = this.$t(this.$route.name);
        const campaignName = this.currentDashboard.name;
        const period = this.dashboardFilterDates.text;
        const date = this.$date().format('ddd DD MMM HH_mm');

        return `${pageName} - ${campaignName} - ${period} - ${date}`;
      },
      defaultOptions() {
        return {
          periodFrom: this.datesScope.dateBegin,
          periodTo: this.datesScope.dateEnd,
          request: this.requestObj,
          tickUnit: this.dashboardFilterUnit,
          to: this.datesScope.dateEnd,
          periodNumber: 6,
          min: 1,
          max: 5.1
        }
      },
      chartOptions() {
        return {
          chart: {
            type: "line",
            height: "400px"
          },
          plotOptions: {
            series: {
              negativeColor: this.$colors.error,
              connectNulls: this.periodType === 'genesis'
            }
          },
          xAxis: {
            min: 1,
            max: 6
          },
          yAxis: {
            tickInterval: 1
          },
          legend: {
            enabled: true
          }
        }
      }
    },
    methods: {
      changeLoadingStatus(status) {
        this.loading = status;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'

  .widget-evolution-header
    height: $widget-header-height
</style>
<template>
  <v-row
    no-gutters
    class="verbatim-topic-rankings"
    v-if="currentItem"
    :style="{ maxWidth: '100%' }"
  >
    <VerbatimTopicRanking
      :scope="scope"
      :strengths="true"
      :ranking="ranking"
      :loading="!ranking"
      :currentItem="currentItem"
    />

    <VerbatimTopicRanking
      :scope="scope"
      :weaknesses="true"
      :ranking="ranking"
      :loading="!ranking"
      :currentItem="currentItem"
    />

    <CompetitivityTopicRanking
      v-if="!fullScreenMode"
      :scope="scope"
      :ranking="ranking"
      :loading="!ranking"
      :currentItem="currentItem"
    />

    <ExpectationTopicRanking
      v-if="currentItem.type === 'lmBrand'"
      :scope="scope"
      :ranking="ranking"
      :loading="!ranking"
      :currentItem="currentItem"
    />
  </v-row>
</template>

<script>
  import VerbatimTopicRanking from './VerbatimTopicRanking'
  import CompetitivityTopicRanking from './CompetitivityTopicRanking'
  import ExpectationTopicRanking from './ExpectationTopicRanking'
  import { mapGetters } from 'vuex'

  export default {
    name: "VerbatimTopicRankings",
    props: [ 'campaignSmartBenchmarkPreferenceId', 'scope', 'currentItem' ],
    components: {
      VerbatimTopicRanking,
      CompetitivityTopicRanking,
      ExpectationTopicRanking
    },
    computed: {
      ...mapGetters([
        'sqlDatesScope',
        'currentDashboard',
        'lmGmbPlaceIdsScope',
        'fullScreenMode',
        'placeIds'
      ]),
      baseRequest() {
        return this.$basedRequest()
      },
      ranking() {
        const result = this.verbatimTopicRanking?.map(row => ({
          ...row,
          current: row.ranking.find(item => item.id === Number(this.currentItem.id)),
          user: row.ranking.find(item => this.userScopeIds.includes(item.id))
        }))

        return result
      },
      countryCodes() {
        if (this.scope === 'perimeter') return null

        if (this.scope === 'country_all') return this.currentDashboard.lmCountryCodes

        return [this.scope.split('country_')[1]]
      },
      userScopeIds() {
        return (this.currentItem.type === 'lmBrand' ? this.currentDashboard.lmBrandIds : this.lmGmbPlaceIdsScope)

      },
      rankingColumn() {
        if (this.currentItem.type === 'lmBrand') {
          return {
            lm_brand_verbatim_topic_ranking: {
              as: 'verbatimTopicRanking', params: {
                date_begin: this.sqlDatesScope.dateBegin,
                date_end: this.sqlDatesScope.dateEnd,
                scope: this.scope,
                user_lm_brand_ids: this.currentDashboard.lmBrandIds,
                country_codes: this.countryCodes,
                place_ids: this.scope === 'perimeter' ? this.placeIds : null
              }
            }
          }
        } else if (this.currentItem.type === 'lmPlace') {
          return {
            lm_place_verbatim_topic_ranking: {
              as: 'verbatimTopicRanking', params: {
                place_id: this.currentItem.placeId,
                date_begin: this.sqlDatesScope.dateBegin,
                date_end: this.sqlDatesScope.dateEnd
              }
            }
          }
        }
      }
    },
    asyncComputed: {
      async verbatimTopicRanking() {
        const request = this.baseRequest.select({
          campaign_smart_benchmark_preferences: [
            this.rankingColumn
          ]
        }).where({
          id: this.campaignSmartBenchmarkPreferenceId
        })

        const response = (await this.$resolve(request))?.first()

        return response.verbatimTopicRanking
      }
    }
  }
</script>

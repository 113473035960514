import { render, staticRenderFns } from "./CampaignIndicatorsTableWidget.vue?vue&type=template&id=0d7fc0a0&scoped=true"
import script from "./CampaignIndicatorsTableWidget.vue?vue&type=script&lang=js"
export * from "./CampaignIndicatorsTableWidget.vue?vue&type=script&lang=js"
import style0 from "./CampaignIndicatorsTableWidget.vue?vue&type=style&index=0&id=0d7fc0a0&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d7fc0a0",
  null
  
)

export default component.exports
const GRID_LINE_COLOR = '#deefee'
const GRID_LINE_WIDTH = 1

export default {
  methods: {
    chartsDefaultOptionsMixin_yAxisLabelsFormatter() {
      return null
    }
  },
  computed: {
    chartsDefaultOptionsMixin_sharedDefaultOptions() {
      const _this = this

      return {
        chart: {
          backgroundColor: '#fff',
          borderColor: "transparent",
          borderWidth: 1,
          height: "300px",
          style: {
            fontFamily: 'SourceSansPro',
            fontSize: '14px'
          },
          events: {
            render: function () {
              setTimeout(function () { window.dispatchEvent(new Event('resize')) }, 100)
            }
          }
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        title: {
          text: ""
        },
        xAxis: {
          title: { text: "" },
          gridLineWidth: GRID_LINE_WIDTH,
          gridLineColor: GRID_LINE_COLOR,
          fill: GRID_LINE_COLOR,
          labels: {
            style: {
              color: '#666666'
            }
          }
        },
        yAxis: {
          title: { text: "" },
          gridLineWidth: GRID_LINE_WIDTH,
          gridLineColor: GRID_LINE_COLOR
        },
        plotOptions: {
          series: {
            lineWidth: 3,
            connectNulls: true,
            clip: false,
            marker: {
              fillColor: '#fff',
              lineWidth: 2,
              lineColor: null,
              symbol: "circle",
            },
            negativeColor: this.$colors.error
          },
          spline: {
            dataLabels: {
              enabled: false
            }
          }
        },
        tooltip: {
          shared: false,
          useHTML: true,
          shadow: false,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          formatter: function () {
            const content = _this.tooltipableChartMixin_tooltipContent ? _this.tooltipableChartMixin_tooltipContent(this) : _this.tooltipableChartMixin_defaultTooltipContent(this)
            const tooltipClass = (_this.tooltipableChartMixin_tooltipPosition(this) === "top") ? "tooltip-top" : "tooltip-bottom"
            return `<div class="${tooltipClass}">
              ${content}
            </div>`
          },
          positioner: function (labelWidth, labelHeight) {
            const offset = 12
            const chart = this.chart
            const point = chart.hoverPoint
            if (!point) return { x: 0, y: 0 }
            const x = chart.plotLeft + point.plotX - labelWidth / 2
            const y = (_this.tooltipableChartMixin_tooltipPosition(this.chart) === "top") ?
              (chart.plotTop + point.plotY) - (labelHeight + offset) :
              (chart.plotTop + point.plotY + offset)

            return { x, y };
          }
        },
      }
    }
  }
}
import { render, staticRenderFns } from "./FreeAnswerCard.vue?vue&type=template&id=19ac4416&scoped=true"
import script from "./FreeAnswerCard.vue?vue&type=script&lang=js"
export * from "./FreeAnswerCard.vue?vue&type=script&lang=js"
import style0 from "./FreeAnswerCard.vue?vue&type=style&index=0&id=19ac4416&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ac4416",
  null
  
)

export default component.exports
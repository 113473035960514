<template>
  <WStatsWidget
    :footerSpacer="footerSpacer"
    :linkTo="{
      name: 'Competitions',
      params: { mode: 'local', scope: 'perimeter', periodType: 'period', currentItemId: currentItem.id }
    }"
    :loading="rankingLoading"
    :subtitle="defaultSubtitle"
    :title="title"
    class="lrm-benchmark-widget"
    height="200px"
    contentWidth="100%"
  >
    <template #content>
      <div class="text-center">
        <w-position-widget
          :position="ranking && rank"
          :evolution="ranking && rankEvolution"
          :displayEvolution="displayEvolution"
          :nrTooltipText="rankMessage"
          :iconInfoColor="getIconInfoColor"
        />
      </div>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import WidgetPosition from '../../../Competitions/widgets/Position'

export default {
  name: 'LrmBenchmarkWidget',
  components: {
    WidgetPosition
  },
  mixins: [
    WidgetMixin
  ],
  props: {
    footerSpacer: { type: Boolean },
  },
  data() {
    return {
      rankingDataLoading: true
    }
  },
  computed: {
    ...mapGetters([
      'isMonoPlace',
      'networkRankingName',
      'currentDashboard',
      'currentDashboardLrmCampaign',
      'placeIdsScope',
      'lmGmbPlaceIdsScope',
      'lrmDashboardFilterRequest',
      'datesScope',
      'currentDashboardBasedTable'
    ]),
    title() {
      return `${this.$t('lrm_benchmark_widget_title_local')} ${this.benchmarkName}`
    },
    networkTranslation() {
      return this.$store.getters.currentLexicon.translate('network', 'network') || networkRankingName
    },
    lmBrandIds() {
      return this.campaignSmartBenchmarkPreference?.lmBrandIds
    },
    currentItem() {
      return {
        type: 'lmPlace',
        id: this.lmGmbPlaceIdsScope[0],
        placeId: this.placeIdsScope[0],
        isUserItem: true
      }
    },
    currentItemRanking() {
      if (this.loading) return undefined

      return this.ranking.find(item => {
        return item.id === Number(this.currentItem.id) ||
          (this.currentItem?.isUserItem && item.id === -1)
      })
    },
    rank() {
      return this.currentItemRanking?.rank
    },
    rankEvolution() {
      return this.currentItemRanking?.rankEvolution
    },
    rankMessage() {
      return this.currentItemRanking?.rankMessage
    },
    getIconInfoColor() {
      return ['not_scraped'].includes(this.currentItemRanking?.rankStatus) ? 'c-yellow-darken' : 'c-lightgrey'
    },
    displayEvolution() {
      return this.datesScope.key !== 'genesis';
    },
    benchmarkName() {
      if (this.campaignSmartBenchmarkPreference?.enabled && this.campaignSmartBenchmarkPreference?.name !== 'default') {
        return this.campaignSmartBenchmarkPreference?.name
      }

      return ''
    },
    ranking() {
      this.currentItem
      return this.rows?.map(row => {
        return this.mapping(row);
      }) || undefined
    },
    rankingLoading() {
      return this.rankingDataLoading || this.ranking === undefined
    }
  },
  methods: {
    mapping(row) {
      return {
        id: row.id,
        rank: row.rank,
        rankEvolution: row.rankEvolution,
        name: row.name,
        nbReview: row.nbReview,
        rankable: row.rankable,
        rankStatus: row.rankStatus,
        scrapedAt: row.scrapedAt,
        rankMessage: row.rankMessage,
      }
    }
  },
  asyncComputed: {
    rows: {
      async get() {
        this.rankingDataLoading = true

        const request = this.lrmDashboardFilterRequest.select({
          [this.currentDashboardBasedTable]: [{
            lrm_ranking: { params: {
              mode: 'local',
              scope: 'perimeter',
              period: 'period',
              place_ids: this.placeIdsScope,
              date_begin: this.datesScope.dateBegin,
              date_end: this.datesScope.dateEnd,
              compared_date_begin: this.datesScope.comparedDateBegin,
              compared_date_end: this.datesScope.comparedDateEnd,
              competitor_lm_brand_ids: this.lmBrandIds
            }}
          }]
        })

        const data = (await this.$resolve(request)).first()?.lrmRanking || []

        this.rankingDataLoading = false

        return data
      },
      default: null
    },
    campaignSmartBenchmarkPreference: {
      default: undefined,
      async get() {
        const request = this.$basedRequest().select({
          campaign_smart_benchmark_preferences: [
            { 'eligible_lm_brand_ids': { as: 'lmBrandIds' } },
            { 'name': { as: 'name' } },
            { 'enabled': { as: 'enabled' } }
          ]
        }).where({
          is_default: true,
          campaign_id: this.currentDashboardLrmCampaign.id
        })

        const campaignSmartBenchmarkPreferences = (await this.$resolve(request))?.data?.campaignSmartBenchmarkPreferences || []

        campaignSmartBenchmarkPreferences.forEach((campaignSmartBenchmarkPreference) => {
          campaignSmartBenchmarkPreference.lmBrandIds = [
            ...this.currentDashboard.lmBrandIds,
            ...campaignSmartBenchmarkPreference.lmBrandIds
          ]
        })

        return campaignSmartBenchmarkPreferences[0]
      }
    }
  }
}
</script>

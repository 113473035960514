<template>
  <span class="d-flex">
    <span
      class="outlined-badge"
      :style="badgeOutlineColor"
      :class="[
        {
          'small': small,
          'fs-9': small,
          'fs-14': !small
        }
      ]">
    <span class="content">{{ content }}</span>
    </span>
  </span>
</template>

<script>
  export default {
    name: "WBadgeOutlined",
    props: {
      content: {
        type: [String, Number],
        required: true,
      },
      small: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: 'purple'
      }
    },
    computed: {
      badgeOutlineColor() {
        return { borderColor: `${this.color} !important`, color: `${this.color}` }
      }
    },
  };
</script>

<style lang="stylus">
@import '~@theme/colors.styl'
.outlined-badge
  display: inline-flex
  align-items: center
  justify-content: center
  position: relative
  height: 20px
  width: 20px
  font-size: 14px
  box-sizing: border-box
  border-radius: 50%
  border: 1px solid

  &.small
    height: 14px
    width: 14px
    font-size: 9px

  .content
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    text-align: center
    line-height: 20px
    margin: 0
    padding: 0

    &.small
      line-height: 14px
</style>

<template>
  <v-btn-toggle
    class="scope-navigation"
    mandatory
    @change="onScopeChanged"
  >
    <v-btn
      class="fs-13"
      :value="defaultCountry"
      height="30"
      :ripple="false"
      :class="{'active': scope !== 'perimeter'}"
    >
      {{ $t('national_scope') }}
    </v-btn>
    <v-btn
      class="fs-13"
      value="perimeter"
      height="30"
      :ripple="false"
      :class="{'active': scope === 'perimeter'}"
    >
      {{ $t('perimeter_scope') }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default{
    name: "ScopeNavigation",
    props: ['scope'],
    computed: {
      ...mapGetters([
        'placeIds'
      ])
    },
    asyncComputed: {
      defaultCountry: {
        async get() {
          const request = this.$basedRequest().select({
            places: [
              { 'COUNT_DISTINCT_lm_places_id': { as: 'count' } }
            ]
          }).where({
            'id': this.placeIds
          }).group(['lm_places_country_code'])

          const result = (await this.$resolve(request))?.data || {}
          const countries = Object.keys(result)

          if (countries.length === 1 ) return `country_${countries[0]}`

          return 'country_all'
        }
      }
    },
    methods: {
      onScopeChanged(scope) {
        this.$emit('scopeChanged', scope)
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .scope-navigation
    text-transform: unset
    background-color: white
    .v-btn--active::before
      opacity: 0 !important
    .v-btn
      letter-spacing: normal
      text-transform: none !important
      background-color: white !important
      color: var(--lightgrey) !important
      &.active
        background-color: var(--secondary) !important
        color: white !important
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-flex"},[_c('span',{staticClass:"outlined-badge",class:[
      {
        'small': _vm.small,
        'fs-9': _vm.small,
        'fs-14': !_vm.small
      }
    ],style:(_vm.badgeOutlineColor)},[_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.content))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <RankingTable
    class="zoomed-topic-ranking-table"
    :rows="rows"
    :isMonoPlace="true"
    :competitorMode="competitorMode"
    :displayEvolution="false && sqlDatesScope && sqlDatesScope.key !== 'genesis'"
    v-if="rows"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import RankingTable from '../../RankingTable'

  export default {
    name: "BrandTopZoomedTopicRankingTableicRankingTable",
    props: ['topicRanking'],
    components: {
      RankingTable
    },
    computed: {
      ...mapGetters([
        'sqlDatesScope'
      ]),
      competitorMode() {
        if (this.topicRanking.current.type == 'lm_brand') {
          return 'national'
        } else if  (this.topicRanking.current.type == 'lm_place') {
          return 'local'
        }
      },
      rows() {
        return this.topicRanking.ranking.map(item => {
          return {
            "rank": item.rank,
            "highlighted": false,
            "lmBrandId": item.id,
            "logoUrl": item.logoThumbUrl,
            "name": item.name,
            "nbReview": item.nbReview,
            "avgScore": item.avgScore,
            "nbPlaces": item.nbPlace,
            "avgScoreEvolution": null,
            "rankEvolution": null
          }
        })
      }
    }
  }
</script>
<template>
  <WStatsWidget
    v-if="(nisVoterProportions === undefined && nisSummary === undefined) || campaign === undefined"
    :title="isLcl ? 'Répartition IRC' : $t('nps_distribution_widget_title')"
    :cols="12"
    height="200px"
    :loading="true"
    :empty="false"
  />
  <RepartitionWidget
    v-else
    :title="isLcl ? 'Répartition IRC' : $t('nps_distribution_widget_title')"
    :subtitle="displayedSubtitle"
    :campaign="campaign"
    :basedRequest="nisVoterProportions === null ? basedRequest : undefined"
    :nbPromoters="nisVoterProportions ? nisVoterProportions.nbPromoters : undefined"
    :nbNeutrals="nisVoterProportions ? nisVoterProportions.nbNeutrals : undefined"
    :nbDetractors="nisVoterProportions ? nisVoterProportions.nbDetractors : undefined"
    :promoters-label="nisVoterProportions ? label(nisVoterProportions.nbPromoters) : undefined"
    :neutrals-label="nisVoterProportions ? label(nisVoterProportions.nbNeutrals) : undefined"
    :detractors-label="nisVoterProportions ? label(nisVoterProportions.nbDetractors) : undefined"
    :promoters-tooltip="promotersTooltip"
    :neutrals-tooltip="neutralsTooltip"
    :detractors-tooltip="detractorsTooltip"
    :promoters-legend="$t('score_from_min_to_max', { min: 9, max: 10 })"
    :neutrals-legend="$t('score_from_min_to_max', { min: 7, max: 8 })"
    :detractors-legend="$t('score_from_min_to_max', { min: 0, max: 6 })"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import RepartitionWidget from "@statistics/shared/widgets/RepartitionWidget"
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'
  import { voterNpsRepartitions } from '@platform/pages/dashboard/shared/constants.js'

  export default {
    name: "NpsRepartitionWidget",
    components: {
      RepartitionWidget
    },
    mixins: [
      WidgetMixin,
      NisMixin,
    ],
    props: {
      campaign: { type: Object },
      nisVoterProportions: { type: Object, default: null },
      nisSummary: { type: Object, default: null },
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
      ]),
      columns() {
        return [
          { "nb_review_by_nps": voterNpsRepartitions.detractors },
          { "nb_review_by_nps": voterNpsRepartitions.neutrals },
          { "nb_review_by_nps": voterNpsRepartitions.promoters }
        ]
      },
      basedRequest() {
        return this.dashboardFilterRequest.select({
          voters: this.columns
        }).where({ campaign_id: this.campaign.id })
      },
      promotersTooltip() {
        const nisSummaryData = this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisSummary)

        return this.nisVoterProportions ?
          this.tooltip(
            this.nisVoterProportions.nbPromoters,
            nisSummaryData.promoters
          ) : undefined
      },
      neutralsTooltip() {
        const nisSummaryData = this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisSummary)

        return this.nisVoterProportions ?
          this.tooltip(
            this.nisVoterProportions.nbNeutrals,
            nisSummaryData.neutrals
          ) : undefined
      },
      detractorsTooltip() {
        const nisSummaryData = this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisSummary)

        return this.nisVoterProportions ?
          this.tooltip(
            this.nisVoterProportions.nbDetractors,
            nisSummaryData.detractors
          ) : undefined
      }
    },
    methods: {
      label(count) {
        return this.$options.filters.toPercent(
          count / (this.nisVoterProportions.nbPromoters + this.nisVoterProportions.nbNeutrals + this.nisVoterProportions.nbDetractors)
        ).toString()
      },
      tooltip(count, transactionalUnitValue) {
        const revenue = this.nisMixin_transactionalUnit([this.campaign]).currency ?
                        transactionalUnitValue.toLocaleString(
                          this.$i18n.locale,
                          {
                            style: 'currency',
                            currency: this.nisMixin_transactionalUnit([this.campaign]).currency,
                            maximumFractionDigits: 2
                          }
                        ) :
                        transactionalUnitValue.toFixed(1).replaceAll('.', ',')

        if (this.isLcl) {
          return this.$t(`nps_repartition_widget_${this.nisMixin_transactionalUnit([this.campaign]).nisColumnName}_label_lcl`, {
            count,
            percentage: this.label(count, transactionalUnitValue),
            transactional_unit_value: revenue
          })
        } else {
          return this.$t(`nps_repartition_widget_${this.nisMixin_transactionalUnit([this.campaign]).nisColumnName}_label`, {
            count,
            percentage: this.label(count, transactionalUnitValue),
            transactional_unit_value: revenue
          })
        }

      },
    }
  }
</script>

<template>
  <div class="dashboard-menus">
    <div v-if="currentDashboard.name">
      <template
        v-for="item in items"
      >
        <v-list-group
          v-if="item.childs"
          :key="item.title"
          :value="item.opened"
          color="grey"
          :prepend-icon="item.icon"
          :ripple="false"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <WSideBarListItem
            v-for="child in item.childs"
            :key="child.title"
            :item="child"
          />
        </v-list-group>

        <WSideBarListItem
          v-else
          :key="item.title"
          :item="item"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    name: "DashboardMenus",
    components: {
    },
    data() {
      return {
        initialRouteName: this.$route.name
      }
    },
    computed: {
      ...mapGetters(
        [
          'currentDashboard',
          'currentUser',
          'currentAdmin',
          'isDemoUser',
          'defaultRanking',
          'hasAccessToNewPlatformFeedbackTab',
          'hasAccessToNewPlatformLocalInsightTab',
          'hasAccessToNewPlatformRankingTab',
          'hasAccessToNewPlatformStatisticTab',
          'hasAccessToNewPlatformAchievementsTab',
          'currentDashboardAchievementsEnabled',
          'currentDashboardScopedCampaigns',
          'isNetworkMonoPlace',
          'voterInfoRankingOptions',
          'hasAccessToOldPlatform',
          'dashboardFilterBase64',
          'isDashboardMono',
          'mainCampaign'
        ]
      ),
      isCurrentUserHasNoTabAccess() {
        return !this.hasAccessToNewPlatformFeedbackTab &&
          !this.hasAccessToNewPlatformLocalInsightTab &&
          !this.hasAccessToNewPlatformRankingTab &&
          !this.hasAccessToNewPlatformStatisticTab &&
          !(this.hasAccessToNewPlatformAchievementsTab && currentDashboardAchievementsEnabled)
      },
      dashboardTitle() {
        return this.currentDashboard.name || this.$t('dashboard_sidebar_dashboard_default_title');
      },
      displayDashboardSidebarProduct() {
        return this.currentDashboardScopedCampaigns.filter(
          (campaign) => campaign.hasProductCategoryType
        ).length > 0
      },
      items() {
        let items = [
          {
            icon: "mdi-view-dashboard-outline", // poll
            title: "dashboard_sidebar_statistics",
            notifications: 0,
            show: this.hasAccessToNewPlatformStatisticTab,
            to: { name: "Statistics", params: { sectionType: 'summary', sectionId: 1 }, query: { dashboard_filters: this.dashboardFilterBase64 } }
          },
          {
            icon: "mdi-format-list-numbered",
            title: "dashboard_sidebar_ranking",
            notifications: 0,
            show: this.hasAccessToNewPlatformRankingTab && (this.voterInfoRankingOptions || !this.isNetworkMonoPlace),
            to: this.rankingPageRoute
          },
          {
            icon: "mdi-star-outline",
            title: "votedashboard_sidebar_voters",
            notifications: 0,
            show: this.hasAccessToNewPlatformFeedbackTab || this.isCurrentUserHasNoTabAccess,
            to: { name: "Feedbacks", params: { status: 'all', subStatus: 'all' }, query: { dashboard_filters: this.dashboardFilterBase64 } }
          },
          {
            icon: "mdi-pin-outline",
            title: "dashboard_sidebar_competitors",
            notifications: 0,
            show: this.hasAccessToNewPlatformLocalInsightTab && this.currentDashboard.preferences.isLrmEligible,
            to: { name: "Competitions", params: { mode: "local", scope: 'perimeter', periodType: 'period', currentItemId: 'default' }, query: { dashboard_filters: this.dashboardFilterBase64 } }
          },
          {
            icon: "mdi-human-handsup",
            title: "dashboard_sidebar_achievements",
            notifications: 0,
            show: this.hasAccessToNewPlatformAchievementsTab && this.currentDashboardAchievementsEnabled,
            to: { name: "Achievements", params: { page: 'leaderboard' }, query: { dashboard_filters: this.dashboardFilterBase64 } }
          },
          {
            icon: "mdi-cart-outline",
            title: "dashboard-sidebar-products",
            notifications: 0,
            show: this.displayDashboardSidebarProduct,
            to: {
              name: "Products",
              params: {
                mode: "product_categories",
                campaignId: 'default',
                productCategoryId: null
              }
            }
          }
        ]
        return items
      },
      rankingPageRoute() {
        if (this.isNetworkMonoPlace && this.voterInfoRankingOptions) {
          return { name: "Ranking", params: {  rankBy: "voter_info", rankById: this.voterInfoRankingOptions[0].value, scope: this.defaultRanking }, query: { dashboard_filters: this.dashboardFilterBase64 } }
        } else {
          return { name: "Ranking", params: { rankBy: 'places', rankById: 'brand', scope: this.defaultRanking }, query: { dashboard_filters: this.dashboardFilterBase64 } }
        }
      }
    }
  }
</script>

<style lang="stylus">
  @import '~@theme/colors.styl'

  .dashboard-menus
    .v-list-item__icon:first-child
      margin-right: 4px !important

    .v-list-item__icon
      .v-icon
        margin-top: 0px !important
        margin-left: 3px
        font-size: 15px

    .active-item
      color: var(--info) !important
      .v-list-item__icon i, .v-list-item__title
        color: var(--info) !important
</style>


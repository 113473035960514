import $api from '@api'
import BasedQuery from "@api/based/basedQuery"
import filters from "./dashboardContext/filters.js"
import preferences from "./dashboardContext/preferences.js"
import { toSnakeCase } from "@shared/helpers/case.js"
import { Lexicon } from '@i18n/lexicon'

const dashboardContext = {
  state: () => ({
    loaded: null,
    digest: null,
    id: null,
    type: null,
    name: null,
    logoUrl: null,
    minimalNumberOfFeedbackForRanking: null,
    rankingRelatedIndicator: null,
    countryCodes: [],
    lmCountryCodes: [],
    hasNpsQuestion: false,
    currentDashboardPublicPlatforms: [],
    campaignVoterKeyInfoByKeyName: {},
    campaigns: [],
    mainCampaignId: null,
    voterInfoRankingOptions: false,
    treatVotersPeriod: 'genesis',
    trustvillePublicStateFilter: null,
    isTrustvilleEligible: false,
    netImpactScoreSummaries: [],
    smartBenchmarkEnabled: false,
    isOpenClaimAchievementModal: false,
    currentEditingPlaceCampaignAchievementId: null,
    claimAchievementsEvent: null,
    currentDashboardAchievementsEnabled: false,
    insatisfactionPreferenceByCampaignId: {},
    whiteLabelByCampaignId: null,
    lmBrandIds: [],
    lexicon: null,
    lrmCampaignId: null
  }),
  modules: {
    filters,
    preferences
  },
  getters: {
    currentDashboard(state, getters) {
      return state
    },
    smartBenchmarkEnabled(state) {
      return state.smartBenchmarkEnabled || false
    },
    mainCampaignId(state, getters) {
      return getters.currentDashboardScopedCampaigns.find(c => c.hasNpsQuestion)?.id || getters.currentDashboardScopedCampaigns[0]?.id
    },
    mainCampaign(state, getters) {
      return getters.currentDashboardScopedCampaigns[0]
    },
    currentDashboardLrmCampaign(state, getters) {
      return state.campaigns.find((campaign) => campaign.id === state.lrmCampaignId)
    },
    productCampaign(state, getters) {
      return getters.currentDashboardScopedCampaigns.find(c => c.hasProductQuestion)
    },
    currentDashboardScopedCampaigns(state, getters) {
      return state.campaigns.filter((campaign) => getters.campaignIdsScope.includes(campaign.id))
    },
    currentTopicsByCampaignId(state) {
      return state.campaigns.reduce((h, campaign) => {
        h[campaign.id] = [...(campaign?.topics || [])]?.sort((a, b) => a.name?.localeCompare(b.name))
        return h
      }, {})
    },
    currentCampaignIndicatorsByCampaignId(state) {
      return state.campaigns.reduce((h, campaign) => {
        h[campaign.id] = [...(campaign?.campaignIndicators || [])]?.sort((a, b) => a.name?.localeCompare(b.name))
        return h
      }, {})
    },
    isDashboardMulti(state, getters) {
      return state?.type === "DashboardMulti"
    },
    isDashboardMono(state, getters) {
      return state?.type === "DashboardBasic"
    },
    currentDashboardTreatVotersPeriod(state, getters) {
      return state.treatVotersPeriod
    },
    isCurrentDashboardTrustvilleEligible(state, getters) {
      return state.isTrustvilleEligible
    },
    currentDashboardParams(state, getters) {
      const dashboardType = (state.type === "DashboardMulti" ? "multi" : "mono")

      return {
        dashboardId: state.id,
        dashboardType: dashboardType
      }
    },
    currentDashboardCampaignWithId(state) {
      return (campaignId) => {
        return state.campaigns.find((campaign) => campaign.id === Number(campaignId))
      }
    },
    currentDashboardBasedTable(state) {
      return state.type === 'DashboardMulti' ? "dashboard_multis" : "dashboard_basics"
    },
    currentDashboardRequest(state, getters) {
      return (new BasedQuery({ queryName: 'currentDashboardRequest' }).where({
        id: state.id
      }))
    },
    currentDashboardType(state) {
      return state.type
    },
    hasNpsQuestion(state) {
      return state.hasNpsQuestion
    },
    hasQcmQuestion(state) {
      return state.hasQcmQuestion
    },
    currentDashboardRankingRelatedIndicator(state) {
      return state.rankingRelatedIndicator
    },
    currentDashboardRankingRelatedColumn(state, getters) {
      return (getters.currentDashboardRankingRelatedIndicator === "nps_score" ? 'nps' : 'avg_score')
    },
    currentDashboardScoreScale(state, getters) {
      return getters.currentDashboardRankingRelatedColumn === 'nps' ? 10 : getters.dashboardAvgScale?.max
    },
    currentDashboardCampaigns(state, getters) {
      return state.campaigns
    },
    currentDashboardMonoCampaign(state, getters) {
      return state.campaigns[0]
    },
    currentDashboardPublicPlatforms(state) {
      return state.currentDashboardPublicPlatforms
    },
    voterInfoRankingOptions(state) {
      return state.voterInfoRankingOptions
    },
    campaignVoterKeyInfoByKeyName(state) {
      return state.campaignVoterKeyInfoByKeyName
    },
    dashboardAvgScale(state, getters) {
      return (state.campaigns[0]?.avgScoreScale || { min: 0, max: 10 })
    },
    currentDashboardCampaignIds(state) {
      return state.campaigns.map((campaign) => campaign.id)
    },
    currentDashboardCampaignById(state) {
      return state.campaigns.reduce((h, campaign) => { h[campaign.id] = campaign; return h }, {})
    },
    currentDashboardProductCampaignById(state) {
      return state.campaigns.filter(
        campaign => campaign.hasProductQuestion
      ).reduce((h, campaign) => { h[campaign.id] = campaign; return h }, {})
    },
    mainWizvilleCampaignWithTopics(state, getters) {
      return getters.currentDashboardScopedCampaigns
        .find((campaign) => campaign.sources.includes('wizville') && campaign.topics.length)
    },
    currentDashboardAchievementsEnabled(state) {
      return state.campaigns.find((campaign) => campaign.achievementsEnabled) != null
    },
    isOpenClaimAchievementModal(state) {
      return state.isOpenClaimAchievementModal
    },
    currentEditingPlaceCampaignAchievementId(state) {
      return state.currentEditingPlaceCampaignAchievementId
    },
    claimAchievementsEvent(state) {
      return state.claimAchievementsEvent
    },
    currentDashboardWhiteLabelByCampaignId(state) {
      return state.whiteLabelByCampaignId
    },
    currentDashboardInsatisfactionNotificationEnabled(state) {
      return !!Object.values(state.insatisfactionPreferenceByCampaignId)
        .find(insatisfactionPreference => insatisfactionPreference.admins === true)
    },
    currentDashboardInsatisfactionPreferenceReferralByCampaignId(state) {
      const campaign_ids = state.campaigns.map((campaign) => campaign.id)
      let campaign_referal = campaign_ids.map((campaign_id) => {
        return {
          campaign_id: campaign_id,
          referral: state.insatisfactionPreferenceByCampaignId[campaign_id].referral
        }
      })
      return campaign_referal
    },
    dashboardLexicon(state) {
      return state.lexicon ? new Lexicon(state.lexicon) : null
    }
  },
  mutations: {
    setDashboardContext(state, data) {
      state.loaded = Date.now()
      state.digest = data.digest
      state.id = data.id
      state.type = data.type
      state.name = data.name
      state.logoUrl = data.logoUrl
      state.countryCodes = data.countryCodes
      state.lmCountryCodes = data.lmCountryCodes
      state.rankingRelatedIndicator = data.rankingRelatedIndicator
      state.minimalNumberOfFeedbackForRanking = data.minimalNumberOfFeedbackForRanking
      state.hasNpsQuestion = data.hasNpsQuestion
      state.hasQcmQuestion = data.hasQcmQuestion
      state.smartBenchmarkEnabled = data.smartBenchmarkEnabled
      state.currentDashboardPublicPlatforms = data.publicPlatforms
      state.campaigns = data.campaigns
      state.campaignVoterKeyInfoByKeyName = data.campaignVoterKeyInfoByKeyName
      state.voterInfoRankingOptions = data.voterInfoRankingOptions
      state.netImpactScoreSummaries = data.netImpactScoreSummaries
      state.insatisfactionPreferenceByCampaignId = data.insatisfactionPreferenceByCampaignId
      state.whiteLabelByCampaignId = data.whiteLabelByCampaignId
      state.currentDashboardAchievementsEnabled = data.currentDashboardAchievementsEnabled
      state.lmBrandIds = data.lmBrandIds
      state.lexicon = data.lexicon
      state.isTrustvilleEligible = data.isTrustvilleEligible
      state.lrmCampaignId = data.lrmCampaignId
    },
    setIsOpenClaimAchievementModal(state, value) {
      state.isOpenClaimAchievementModal = value
    },
    setCurrentEditingPlaceCampaignAchievementId(state, value) {
      state.currentEditingPlaceCampaignAchievementId = value
    },
    triggerAchievementsUpdate(state, value) {
      state.claimAchievementsEvent = value
    },
    setTreatVotersPeriod(state, treatVotersPeriod) {
      state.treatVotersPeriod = treatVotersPeriod
    },
    setCurrentDashboardTrustvilleEligible(state, value) {
      state.isTrustvilleEligible = value
    }
  },
  actions: {
    async dashboardContext(context, { dashboardType, dashboardId, dashboardFilters }) {
      const digest = `${dashboardType}_${dashboardId}`

      if (digest !== context.state.digest || !context.state.loaded) {
        const ctx = await $api.wizville.contexts.authDashboard(dashboardType, dashboardId)

        if (ctx) {
          context.commit('setDashboardContext', { digest, ...ctx })
          context.commit('setDashboardPreferences', ctx)

          await context.dispatch('refreshDashboardFilters', { dashboardFilters })
          await context.dispatch('refreshDashboardPreferences')
        }
      }
    },
    async refreshDashboardPreferences(context) {
      await context.dispatch('setDashboardPreferences', { dashboardId: context.state.id, dashboardType: context.state.type })
    },
    async refreshDashboardFilters(context, { dashboardFilters }) {
      if (context.state.loaded) {
        const request = new BasedQuery({ queryName: "refreshDashboardFilters", scope: "user" }).select({
          [`${toSnakeCase(context.state.type)}s`]: ["user_filters"]
        }).where({
          id: context.state.id
        })

        const filters = dashboardFilters || (await request.resolve('refreshDashboardFilters')).first().userFilters

        await context.dispatch('setDashboardFilters', filters)
      }
    },
    async updateDashboardFilters(context, filters) {
      await context.dispatch('setDashboardFilters', filters)
      filters = await $api.wizville.dashboard.setFilters(context.state.type, context.state.id, filters)
    },
    setIsOpenClaimAchievementModal(context, value) {
      context.commit('setIsOpenClaimAchievementModal', value)
    },
    setCurrentEditingPlaceCampaignAchievementId(context, value) {
      context.commit('setCurrentEditingPlaceCampaignAchievementId', value)
    },
    triggerAchievementsUpdate(context, value) {
      context.commit('triggerAchievementsUpdate', value)
    },
    setTreatVotersPeriod(context, treatVotersPeriod) {
      context.commit('setTreatVotersPeriod', treatVotersPeriod)
    },
    setCurrentDashboardTrustvilleEligible(context, value) {
      context.commit('setCurrentDashboardTrustvilleEligible', value)
    }
  }
}

export default dashboardContext

export default {
  methods: {
    tooltipableChartMixin_defaultTooltipContent(graph) {
      return `
        <div style="text-align: center">
          <h1 style="font-size: 14px;">${graph.series.name}</h1>
          <br>
          <span>${graph.point.category}</span>
          <br>
          <span style="font-weight: 700; font-size: 24px;">${Number(graph.point.y).toFixed(this.decimals)}</span>
        </div>`
    },
    tooltipableChartMixin_tooltipContent(graph) {
      let body = `
          <h1 style="font-size: 14px; font-weight: 700;">${graph.point.category}</h1>
          <br>
          <span>${graph.series.name}</span>
          <br>
          <span style="font-weight: 700; font-size: 24px;">${Number(graph.point.y).toFixed(1)}</span>
          <br>
        `

      if (graph.series.userOptions?.rawData && graph.series.userOptions.rawData[graph.point.index]?.nbReview) {
        body += `<span>${this.$t('nb_review', { count: graph.series.userOptions.rawData[graph.point.index].nbReview })}</span>`
      }

      return `<div style="text-align: center;">${body}</div>`
    },
    tooltipableChartMixin_tooltipPosition(graph) {
      const point = graph.point || graph.hoverPoint
      const chart = graph.series.chart || graph.series[0].chart

      if (!point) {
        return "top"
      }

      return (point.plotY > chart.chartHeight / 2) ?
        "top" :
        "bottom"
    }
  }
}
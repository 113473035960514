<template>
  <v-container fluid class="reportings">
    <v-row no-gutters>
      <v-col>
        <v-data-table
          :loading="!reportings"
          :headers="headers"
          :items="reportings"
          group-by="quarterPos"
          hide-default-footer
          disable-pagination
        >
          <template #group.header="{ group }"">
            <td :colspan="headers.length">
              <span class="fb-14"> {{ $helpers.dates.quarterPosHuman(group) }}</span>
            </td>
          </template>

          <template #item.createdAt="{ value }"">
            <WDate :date="value" />
          </template>

          <template #item.period='{ item }'>
            <template v-if="item.period == 'daily'">
              {{ item.dateBegin | day }}
            </template>

             <template v-if="item.period == 'yearly'">
                <v-badge inline color="warning">
                  <template #badge>{{ $t('year')}} {{ item.dateBegin | year }}</template>
                </v-badge>
            </template>

            <template v-if="item.period == 'weekly'">
              <span class="fs-14">{{ item.dateBegin | week }}</span>
            </template>

            <template v-if="item.period == 'monthly'">
              <v-badge inline color="primary">
                <template #badge>{{ item.dateEnd | month | capitalize }}</template>
              </v-badge>
            </template>

            <template v-if="item.period == 'quarterly'">
              <v-badge inline color="success">
                <template #badge>{{ item.dateEnd |quarterYear }}</template>
              </v-badge>
            </template>
          </template>

          <template #item.emailPreviewUrl="{ value }"">
            <div class="text-center">
              <v-btn v-if="value" icon :href="value" target="_blank">
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </div>
          </template>

          <template #item.downloadPdfUrl="{ value }"">
            <div class="text-center">
              <v-btn v-if="value" icon :href="value" target="_blank">
                <v-icon small>mdi-download</v-icon>
              </v-btn>
            </div>
          </template>

          <template #item.downloadZipUrl="{ item, value }">
            <div class="text-center">
              <v-btn v-if="value" icon :href="item.downloadZipUrl" target="_blank">
                <v-icon small>mdi-download</v-icon>
              </v-btn>
              <v-tooltip v-else-if="isAboutToBeProcessed(item)" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-progress-circular indeterminate size="16" />
                  </v-btn>
                </template>
                <span>{{ $t('myreports_is_about_to_be_processed') }}</span>
              </v-tooltip>
              <v-tooltip v-else-if="isProcessing(item)" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-progress-circular size="16" :value="item.zipProgression" />
                  </v-btn>
                </template>
                <span>{{ item.zipProgression }}%</span>
              </v-tooltip>
              <v-tooltip v-else-if="isFailed(item)" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="c-error" v-bind="attrs" v-on="on">
                    mdi-alert-octagon-outline
                  </v-icon>
                </template>
                <span>{{ $t('myreports_zip_failed') }}</span>
              </v-tooltip>
              <v-btn
                v-if="canRegenerateZipFile(item)"
                @click="prepareZipFile(item.id)"
                icon
              >
                <v-icon small>mdi-reload</v-icon>
              </v-btn>
            </div>
          </template>

          <template #item.downloadVerbatimUrl="{ value }"">
            <div class="text-center">
              <v-btn v-if="value" icon :href="value" target="_blank">
                <v-icon small>mdi-download</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "Reportings",
    data() {
      return {
        processingReportingIds: [],
      }
    },
    computed: {
      ...mapGetters([
        'isDashboardMulti',
        'currentDashboardCampaignIds',
        'currentDashboardCampaignById'
      ]),
      headers() {
        const headers = [
          { text: 'Période', value: 'period', sortable: false },
        ]
        if (this.isDashboardMulti) {
          headers.splice(2, 0, {
            text: this.$helpers.string.capitalize(this.$t("campaign")),
            value: 'campaignName',
            sortable: false,
          })
        }
        if (this.isColumnFilled(this.reportings, 'emailPreviewUrl')) {
          headers.splice(3, 0, {
            text: this.$t("myreports_headers_email"),
            value: 'emailPreviewUrl',
            sortable: false,
            align: 'center',
          })
        }
        if (this.isColumnFilled(this.reportings, 'downloadPdfUrl')) {
          headers.splice(4, 0, {
            text: this.$t("myreports_headers_pdf"),
            value: 'downloadPdfUrl',
            sortable: false,
            align: 'center',
          })
        }
        if (this.isColumnFilled(this.reportings, 'downloadZipUrl') ||
            this.columnHasAsyncZipStatus(this.reportings)
        ) {
          headers.splice(5, 0, {
            text: this.$t("myreports_headers_pdf_email"),
            value: 'downloadZipUrl',
            sortable: false,
            align: 'center',
          })
        }
        if (this.isColumnFilled(this.reportings, 'downloadVerbatimUrl')) {
          headers.splice(6, 0, {
            text: this.$t("myreports_headers_verbatim"),
            value: 'downloadVerbatimUrl',
            sortable: false,
            align: 'center',
          })
        }
        return headers
      },
    },
    asyncComputed: {
      reportings: {
        default: [],
        async get() {
          const request = this.$basedRequest().select({
            reportings: [
              'id',
              'created_at',
              'updated_at',
              'period',
              'campaign_id',
              'date_begin',
              'date_end',
              'zip_status',
              'zip_progression',
              'email_preview_url',
              'download_pdf_url',
              'download_zip_url',
              'download_verbatim_url',
            ]
          }).where({
            campaign_id: this.currentDashboardCampaignIds
          }).scoped(
            ['ready']
          ).order(
            ['created_at', 'desc']
          )
          const result = await this.$resolve(request)
          return (result?.data?.reportings || []).map(reporting => (
            {
              ...reporting,
              ...{
                campaignName: this.currentDashboardCampaignById[reporting.campaignId]?.name,
                quarterPos: this.$helpers.dates.quarterPos(reporting.dateEnd),
                year: -Number(reporting.dateBegin.slice(0, 4)),
              }
            }
          ))
        }
      }
    },
    methods: {
      isColumnFilled(reportings, column) {
        return reportings?.find(reporting => reporting[column])
      },
      columnHasAsyncZipStatus(reportings) {
        return reportings?.find(reporting => reporting.zipStatus == 'async')
      },
      isAboutToBeProcessed(reporting) {
        return this.processingReportingIds.includes(reporting.id)
      },
      isProcessing(reporting) {
        return ['scheduled', 'processing'].includes(reporting.zipStatus)
      },
      isFailed(reporting) {
        return reporting.zipStatus == 'failed' ||
               reporting.updatedAt < Date.now() - 1000 * 60 * 60 * 24
      },
      canRegenerateZipFile(reporting) {
        return (reporting.zipStatus == 'async' || this.isFailed(reporting)) &&
               !this.isAboutToBeProcessed(reporting)
      },
      prepareZipFile(reportingId) {
        this.$api.wizville.myReports.prepareZipFile(reportingId)
        this.processingReportingIds.push(reportingId)
        this.$store.dispatch('notifyInfo', {
          message: this.$t('myreports_zip_processing'),
          timeout: 5000
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'

  .reportings
    padding-top: "calc(%s + 15px)" % $filters-height
</style>

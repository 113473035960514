<template>
  <div>
    <v-data-table
      class="zoomed-topic-verbatim-table"
      :loading="loading"
      :headers="headers"
      :items="formatedRows"
      hide-default-header
      fixedHeader
      height="260px"
    >
      <template #item.score="{ value }">
        <v-rating
          class="w-rating d-none d-sm-flex"
          background-color="grey lighten-2"
          readonly
          :value="Math.round(value * 2) / 2"
          half-increments
          length="5"
          size="20"
          :color="$colors.gold"
          v-if="value"
        ></v-rating>
      </template>

      <template #item.verbatim="{ value }">
        <div class="f-12" v-html="value"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _sortBy from 'lodash/sortBy'

  export default {
    name: "ZoomedTopicVerbatimTable",
    props: ['scope', 'currentItem', 'topicId', 'strengths', 'weaknesses'],
    components: {
    },
    data() {
      return {
        loading: true,
        headers: [
          {
            value: 'score',
            text:  this.$t('score'),
            width: '100px',
            class: [ 'flex-shrink-0', 'text-left' ]
          },
          {
            value: 'verbatim',
            text: this.$t("reporting_verbatim"),
            class: [ 'flex-grow-1', 'text-left' ]
          }
        ]
      }
    },
    computed: {
      ...mapGetters([
        'sqlDatesScope',
        'dashboardFilterReady',
      ]),
      formatedRows() {
        if (this.lmPlaceIds) {
          return _sortBy(this.rows?.map(row => {
            return {
              createdAt: row.createdAt,
              verbatim: this.highlightedKeywords(row),
              score: row.score,
              placeName: row.placeName,
              placeUrl: row.placeUrl
            }
          }), r => (this.weaknesses ? r.score : -r.score))
        }
      },
      countryCodes() {
        if (this.scope === 'perimeter') return null

        if (this.scope === 'country_all') return this.currentDashboard.lmCountryCodes

        return [this.scope.split('country_')[1]]
      },
      reviewFilteredRequest() {
        if (!this.dashboardFilterReady) {
          return
        }
        return this.$basedRequest().where({
          lm_place_reviews_lm_place_id: this.lmPlaceIds
        }).where({
          stem_group_topic_id: { ids: this.topicId },
          lm_place_reviews_content: { "<>": "" }
        }).where({
          lm_place_reviews_created_at: { "<=>": { min:  this.sqlDatesScope.dateBegin, max:  this.sqlDatesScope.dateEnd } }
        })
      }
    },
    asyncComputed: {
      async rows() {
        if (this.lmPlaceIds) {
          this.loading = true
          let reviews = []

          const reviewsRequest = this.reviewFilteredRequest.select({
            lm_place_review_keywords: [
              {'lm_place_reviews_created_at': { as: "createdAt" } },
              {'lm_place_reviews_content': { as: "verbatim" } },
              {'lm_place_reviews_score': { as: "score" } },
              {'lexeme_keywords_keyword': { as: "keyword" } },
              {'lexemes_stem': { as: "stem" } },
              {'lm_places_name': { as: "placeName" } },
              {'lm_places_url': { as: "placeUrl" } }
            ]
          }).order(
            [ 'lm_place_reviews_created_at', 'desc' ]
          ).limit(500)

          reviews = (await this.$resolve(reviewsRequest))?.data?.lmPlaceReviewKeywords
          this.loading = false

          return reviews
        }
      },
      async lmPlaceIds() {
        if (this.currentItem.type == 'lmPlace') {
            return [this.currentItem.id]
        } else {
          let request = this.$basedRequest().where({
            lm_brand_id: this.currentItem.id,
          }).select({
            lm_brand_places: [ { lm_place_id: { as: "lmPlaceId" } } ]
          })

          if (this.countryCodes) {
            request = request.where({
              lm_places_country_code: { 'in': this.countryCodes }
            })
          }

          const lmPlaceIds = (await this.$resolve(request))?.data?.lmBrandPlaces.map(e => e.lmPlaceId)

          return lmPlaceIds
        }
      }
    },
    methods: {
      highlightedKeywords(item) {
        return item.verbatim.replaceAll(new RegExp(item.keyword, "ig"), `<span style="color: ${this.$colors.success}; font-weight: bold;">$&</span>`)
      },
    }
  }
</script>

<style lang='stylus'>
</style>


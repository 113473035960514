<template>
  <WStatsWidget
    class="widget-score"
    :title="title"
    :cols="12"
    height="200px"
    contentWidth="100%"
    :loading="loading"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #options>
      <WidgetSettingsMenu
        :mode="mode"
        :scope="scope"
        :periodType="periodType"
        :currentItem="currentItem"
        :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreferenceId"
      />
    </template>

    <template #content>
      <WStatsScore
        :key="periodType"
        :score="ranking && score"
        :evolution="ranking && scoreEvolution"
        :infoMessageReviews="ranking && rankMessage"
        :infoLink="urlToBenchmark"
        iconInfoReviewColor="c-yellow-darken"
        :displayEvolution="displayEvolution"
        :isFiveScore="true"
        :nbReview="ranking && nbReview"
        :decimals="2"
        source="google"
        class="pb-2"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import WidgetSettingsMenu from '../WidgetSettingsMenu'
  import HeaderSubtitle from './../shared/HeaderSubtitle'
  import _uniq from 'lodash/uniq'

  export default {
    name: "WidgetScore",
    components: {
      WidgetSettingsMenu,
      HeaderSubtitle
    },
    props: {
      mode: {
        required: true,
        type: String
      },
      scope: {
        required: true,
        type: String
      },
      periodType: {
        required: true,
        type: String
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      },
      currentItem: {
        required: false
      },
      ranking: {
        required: false
      },
      loading: {
        required: false
      }
    },
    data() {
      return {
        urlToBenchmark: 'https://wizville.zendesk.com/hc/fr/articles/7909896871196-Benchmark-Avis-Google-R%C3%A8gles-de-collecte-des-avis'
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboardBasedTable",
        "dashboardFilterDates",
        "dashboardFilterBase64",
        "placeIdsScope",
        "datesScope",
        "isMonoPlace",
        'dashboardFilterReady',
      ]),
      displayEvolution() {
        return this.datesScope.key !== 'genesis' && this.score?.avgScore !== null
      },
      title() {
        const title = this.$t("competitors_widget_average_score_title")

        if (this.isMonoPlace &&
            this.mode === "local" &&
            this.currentItem?.isUserItem === false
          ) {
          return `${title} - ${this.currentItem.name}`
        }

        return title
      },
      currentItemRanking() {
        if (this.loading) return undefined

        if (this.mode === 'local' && !this.isMonoPlace) return null

        return this.ranking.find(item => {
          return item.id === Number(this.currentItem.id) ||
            (this.currentItem?.isUserItem && item.id === -1)
        })
      },
      itemsWithScore() {
        return this.ranking.filter(item => !!item.avgScore)
      },
      score() {
        if (this.mode === 'local' && !this.isMonoPlace) {
          return this.ranking.reduce((score, item) => score + (item.avgScore || 0), 0) / this.itemsWithScore.length
        }
        return this.currentItemRanking?.avgScore
      },
      scoreCompared() {
        if (this.mode === 'local' && !this.isMonoPlace) {
          return this.ranking.reduce((score, item) => score + (item.avgScoreCompared || 0), 0) / this.itemsWithScore.length
        }
        return this.currentItemRanking?.avgScoreCompared
      },
      scoreEvolution() {
        return this.score - this.scoreCompared
      },
      rankMessage() {
        if (this.mode === 'local' && !this.isMonoPlace) {
          return _uniq(this.ranking.map(item => item.rankMessage)).pop()
        }
        return this.currentItemRanking?.rankMessage
      },
      nbReview() {
        if (this.mode === 'local' && !this.isMonoPlace) {
          return this.ranking.reduce((nbReview, item) => nbReview + (item.nbReview || 0), 0)
        }
        return this.currentItemRanking?.nbReview
      }
    }
  }
</script>

<style lang='stylus'>
  @import '~@theme/constants.styl'

  .widget-score-header
    height: $widget-header-height
  .widget-score-main-title
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

</style>

<template>
  <WStatsWidget
    v-if="topic === undefined || nisTopicSummary === undefined"
    :title="$t('topic_roi_satisfaction_evolution_title')"
    :cols="12"
    :loading="true"
    :empty="false"
  />
  <EvolutionWidget
    v-else
    :title="$t('topic_roi_satisfaction_evolution_title')"
    :basedRequest="basedRequest"
    :campaign="campaign"
    indicatorAlias="value"
    :decimals="1"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import NisMixin from '@statistics/shared/nis_mixin'
import EvolutionWidget from '@statistics/shared/widgets/EvolutionWidget'
import { voterAvgScoreRepartitions } from '@platform/pages/dashboard/shared/constants.js'

export default {
  name: "TopicRoiSatisfactionEvolutionWidget",
  components: {
    EvolutionWidget
  },
  mixins: [
    WidgetMixin,
    NisMixin,
  ],
  props: {
    campaign: { type: Object, required: true },
    topic: { type: Object },
    nisTopicSummary: { type: Object },
  },
  methods: {
    computeRoiSatisfaction(nbDetractors, nbNeutrals, nbPromoters, nbReviews) {
      const nbOthers = nbNeutrals + nbDetractors
      const nisTopicSummaryData = this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisTopicSummary)
      const promotersTransactionalUnit =
        nisTopicSummaryData.promoters * nbPromoters

      const othersTransactionalUnit =
        nisTopicSummaryData.neutrals * nbNeutrals +
        nisTopicSummaryData.detractors * nbDetractors

      const transactionalUnitWithoutPromoters = othersTransactionalUnit * (1 + nbPromoters/nbOthers)

      const satisfactionRoi = (promotersTransactionalUnit + othersTransactionalUnit - transactionalUnitWithoutPromoters) / transactionalUnitWithoutPromoters

      return satisfactionRoi * 100
    }
  },
  computed: {
    ...mapGetters([
      'placeIdsScope',
      'dashboardFilterRequest',
    ]),
    nbReviewTopicColumns() {
      return this.campaign.avgScoreScale.max === 5 ?
        [
          { "nb_review_by_avg_score_topic_": { ...voterAvgScoreRepartitions.detractors5, topic_id: this.topic.id }},
          { "nb_review_by_avg_score_topic_": { ...voterAvgScoreRepartitions.neutrals5, topic_id: this.topic.id }},
          { "nb_review_by_avg_score_topic_": { ...voterAvgScoreRepartitions.promoters5, topic_id: this.topic.id }},
        ] :
        [
          { "nb_review_by_avg_score_topic_": { ...voterAvgScoreRepartitions.detractors10, topic_id: this.topic.id }},
          { "nb_review_by_avg_score_topic_": { ...voterAvgScoreRepartitions.neutrals10, topic_id: this.topic.id }},
          { "nb_review_by_avg_score_topic_": { ...voterAvgScoreRepartitions.promoters10, topic_id: this.topic.id }},
        ]
    },
    basedRequest() {
      const dashboardFilterRequest = this.dashboardFilterRequest
      const computeRoiSatisfaction = this.computeRoiSatisfaction
      const nbReviewTopicColumns = this.nbReviewTopicColumns
      const placeIdsScope = this.placeIdsScope
      const campaign = this.campaign
      const uniqueId = Math.random().toString(36).substr(2, 9)

      const requestObj = {
        id: uniqueId,
        dateBegin: null,
        dateEnd: null,
        groups: null,
        dateBetween(dateBegin, dateEnd) {
          this.dateBegin = dateBegin
          this.dateEnd = dateEnd
          return this
        },
        group(groups) {
          this.groups = groups
          return this
        },
        async resolve(
          queryName,
          {
            tickNumber,
            tickUnit,
            timeSerieParams
          },
          endPoint
        ) {
          let request = dashboardFilterRequest.select({
            voters: nbReviewTopicColumns.concat([
              { nb_review: { as: 'nbReview' } }
            ])
          })
          .where({ campaign_id: campaign.id })
          .group(this.groups)
          .dateBetween(this.dateBegin, this.dateEnd)

          if (placeIdsScope && placeIdsScope.length) {
            request = request.where({
              place_campaigns_place_id: placeIdsScope,
            })
          }

          const data = (await request.resolve(
                         "TopicRoiSatisfactionEvolutionWidget",
                         {
                           tickNumber: tickNumber,
                           tickUnit: tickUnit,
                           timeSerieParams: timeSerieParams
                         },
                         "time_series"
                       ))?.data || {}

          if (data.series[0]) {
            data.series[0].data = data.series[0].data.map((data) => {
              const value = data ?
                            computeRoiSatisfaction(
                              data.nbDetractors,
                              data.nbNeutrals,
                              data.nbPromoters,
                              data.nbReviews
                            ) :
                            null
              const nbReview = data?.nbReview || null

              return { value, nbReview }
            })
          }

          return { data: data || {} }
        }
      }

      return Object.create(requestObj)
    }
  }
}
</script>

<template>
  <div
    v-if="currentDashboard.preferences.isLrmEligible && campaignSmartBenchmarkPreferences !== undefined && currentDashboardLrmCampaign !== undefined"
    class="competitions"
    :class="contentClass"
  >
    <div
      class="d-flex align-center flex-column justify-space-between flex-md-row competitions-navigation py-1 pr-3"
      :class="navigationClass"
    >
      <div class="d-flex align-center ga-2">
        <ModeNavigation
          v-if="!fullScreenMode"
          :campaignSmartBenchmarkPreferences="enabledCampaignSmartBenchmarkPreferences"
        />
        <CampaignSmartBenchmarkPreferenceAutocomplete
          v-if="showCampaignSmartBenchmarkPreferenceAutocomplete && $vuetify.breakpoint.smAndDown"
          :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference?.id"
          :campaignSmartBenchmarkPreferences="enabledCampaignSmartBenchmarkPreferences"
          @campaignSmartBenchmarkPreferenceIdChanged="campaignSmartBenchmarkPreferenceIdChanged"
        />
      </div>
      <div class="d-flex align-center ga-2">
        <CampaignSmartBenchmarkPreferenceAutocomplete
          v-if="showCampaignSmartBenchmarkPreferenceAutocomplete && $vuetify.breakpoint.mdAndUp"
          :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreference?.id"
          :campaignSmartBenchmarkPreferences="enabledCampaignSmartBenchmarkPreferences"
          @campaignSmartBenchmarkPreferenceIdChanged="campaignSmartBenchmarkPreferenceIdChanged"
        />
        <CountryAutocomplete
          v-if="showCountryAutocomplete"
          :scope="scope"
          @scopeChanged="onScopeChanged"
        />
        <ScopeNavigation
          v-if="showScopeNavigation"
          :scope="scope"
          @scopeChanged="onScopeChanged"
        />
      </div>
    </div>

    <LmPlaces
      :mode="mode"
      :scope="scope"
      :currentItem="currentItem"
      :periodType="periodType"
      :key="`lm-places-${mode}-${scope}-${periodType}-${campaignSmartBenchmarkPreference?.id}`"
      :campaignSmartBenchmarkPreference="campaignSmartBenchmarkPreference"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import LmPlaces from './LmPlaces'
  import CountryAutocomplete from './navigation/CountryAutocomplete'
  import ModeNavigation from './navigation/ModeNavigation'
  import ScopeNavigation from './navigation/ScopeNavigation'
  import CampaignSmartBenchmarkPreferenceAutocomplete from './navigation/CampaignSmartBenchmarkPreferenceAutocomplete'
  import DocumentScrollMixin from '@shared/mixins/document_scroll'

  export default {
    name: "CompetitionsPage",
    components: {
      CountryAutocomplete,
      ModeNavigation,
      ScopeNavigation,
      CampaignSmartBenchmarkPreferenceAutocomplete,
      LmPlaces,
    },
    mixins: [
      DocumentScrollMixin
    ],
    props: {
      mode: {
        required: true,
        type: String
      },
      scope: {
        required: true,
        type: String
      },
      periodType: {
        required: true,
        type: String
      },
      currentItemId: {
        required: true
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboard",
        "currentDashboardCampaignIds",
        "isMonoPlace",
        "lmGmbPlaceIdsScope",
        "fullScreenMode",
        "sidebarMinimized",
        "placeIds",
        "currentDashboardLrmCampaign"
      ]),
      showShadow() {
        return this.documentScroll_isScrolling || this.$vuetify.breakpoint.xsOnly
      },
      showScopeNavigation() {
        return !this.fullScreenMode && (this.mode === 'national') && this.enabledCampaignSmartBenchmarkPreferences.length > 0
      },
      showCountryAutocomplete() {
        return !this.fullScreenMode && (this.mode === 'national' && this.scope !== 'perimeter')
      },
      showCampaignSmartBenchmarkPreferenceAutocomplete() {
        return !this.fullScreenMode && this.enabledCampaignSmartBenchmarkPreferences.length > 0
      },
      navigationClass() {
        return {
          'width-sidebar-mini': this.sidebarMinimized && this.$vuetify.breakpoint.smAndUp,
          'width-sidebar-maxi': !this.sidebarMinimized && this.$vuetify.breakpoint.smAndUp,
          'shadow-bottom': this.showShadow
        }
      },
      contentClass() {
        return {
          'top-big': this.$vuetify.breakpoint.smAndDown && this.mode === 'national'
        }
      },
      showScopeNavigation() {
        return !this.fullScreenMode && (this.mode === 'national')
      },
      showCountryAutocomplete() {
        return !this.fullScreenMode && (this.mode === 'national' && this.scope !== 'perimeter')
      },
      enabledCampaignSmartBenchmarkPreferences() {
        return this.campaignSmartBenchmarkPreferences?.filter(
          (campaignSmartBenchmarkPreference) => campaignSmartBenchmarkPreference.enabled
        )
      },
      defaultCampaignSmartBenchmarkPreference() {
        return this.enabledCampaignSmartBenchmarkPreferences?.find(
          (campaignSmartBenchmarkPreference) => campaignSmartBenchmarkPreference.isDefault
        )
      },
      campaignSmartBenchmarkPreference() {
        return this.enabledCampaignSmartBenchmarkPreferences?.find(
          (campaignSmartBenchmarkPreference) => campaignSmartBenchmarkPreference.id === Number(this.campaignSmartBenchmarkPreferenceId)
        ) || this.defaultCampaignSmartBenchmarkPreference
      }
    },
    asyncComputed: {
      currentItem: {
        async get() {
          if (this.mode === 'local') {
            const itemId = this.currentItemId === 'default' ?
              Number(this.lmGmbPlaceIdsScope[0]) :
              Number(this.currentItemId)

            return await(this.currentLmPlace(itemId))
          } else {
            const itemId = this.currentItemId === 'default' ?
                Number(this.currentDashboard.lmBrandIds[0]) :
                Number(this.currentItemId)

            return await(this.currentLmBrand(itemId))
          }
        }
      },
      campaignSmartBenchmarkPreferences: {
        default: undefined,
        async get() {
          const request = this.$basedRequest().select({
            campaign_smart_benchmark_preferences: [
              { 'id': { as: 'id' } },
              { 'name': { as: 'name' } },
              { 'enabled': { as: 'enabled' } },
              { 'is_default': { as: 'isDefault' } },
              { 'eligible_lm_brand_ids': { as: 'lmBrandIds', params: { country_code: this.countryCode } } },
              { 'min_topic_review_for_ranking': { as: 'minTopicReviewForRanking' } },
              { 'nb_month_for_topic_weight': { as: 'nbMonthForTopicWeight' } },
              { 'by_default_display_themes': { as: 'byDefaultDisplayThemes' } }
            ]
          }).where({
            campaign_id: this.currentDashboardLrmCampaign.id,
          })

          const campaignSmartBenchmarkPreferences = (await this.$resolve(request))?.data?.campaignSmartBenchmarkPreferences || []

          campaignSmartBenchmarkPreferences.forEach((campaignSmartBenchmarkPreference) => {
            campaignSmartBenchmarkPreference.lmBrandIds = [
              ...this.currentDashboard.lmBrandIds,
              ...campaignSmartBenchmarkPreference.lmBrandIds
            ]
          })

          return campaignSmartBenchmarkPreferences
        }
      }
    },
    methods: {
      async currentLmBrand(id) {
        const req = this.$basedRequest().where({
          id: id
        }).select({
          lm_brands: [ 'name' ]
        })

        const result = (await this.$resolve(req)).first()

        return {
          type: 'lmBrand',
          id: id,
          name: result?.name,
          isUserItem: this.currentDashboard.lmBrandIds.includes(Number(id)) || Number(id) === -1
        }
      },
      async currentLmPlace(id) {
        if (this.isMonoPlace) {
          const req = this.$basedRequest().where({
            id: id
          }).select({
            lm_places: [ 'name' ]
          })

          const result = (await this.$resolve(req)).first()

          return {
            type: 'lmPlace',
            id: id,
            name: result?.name,
            placeId: this.placeIds[0],
            isUserItem: this.lmGmbPlaceIdsScope.includes(Number(id)) || Number(id) === -1
          }
        }
      },
      onScopeChanged(scope) {
        if (scope) {
          this.$router.push({
            name: "Competitions",
            params: {
              mode: this.mode,
              scope: scope,
              periodType: this.periodType,
              currentItemId: this.currentItemId,
              campaignSmartBenchmarkPreferenceId: this.campaignSmartBenchmarkPreferenceId
            }
          })
        }
      },
      campaignSmartBenchmarkPreferenceIdChanged(campaignSmartBenchmarkPreferenceId) {
        if (campaignSmartBenchmarkPreferenceId) {
          this.$router.push({
            name: "Competitions",
            params: {
              mode: this.mode,
              scope: this.scope,
              periodType: this.periodType,
              currentItemId: this.currentItemId,
              campaignSmartBenchmarkPreferenceId: campaignSmartBenchmarkPreferenceId
            }
          })
        }
      }
    }
  }
</script>

<style lang="stylus">
  @import '~@theme/constants.styl'

  .competitions
    padding-top: "calc(%s + %s)" % ($app-bar-height $filters-height)
    &.top-big
      padding-top: "calc(%s + %s + 40px)" % ($app-bar-height $filters-height)
    .competitions-navigation
      width: 100%
      background-color: var(--bodygrey)
      position: fixed
      transition: width 250ms
      z-index: 4
      top: "calc(%s + %s + 1px)" % ($app-bar-height $filters-height)
      &.shadow-bottom
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, .2)
      &.width-sidebar-mini
        width: "calc(100% - %s)" % $sidebar-mini-width
      &.width-sidebar-maxi
        width: "calc(100% - %s)" % $sidebar-width
</style>
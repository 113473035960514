<template>
  <div
    v-resize="onResize"
    v-if="mainFiltersOptions && this.$route.name !== 'Collaborators'"
    class="d-flex dashboard-filters filters-row"
  >
    <v-slide-group
      ref="slideGroup"
      :class="{ 'width100': $vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.xsOnly }"
      show-arrows
    >
      <v-slide-item class="flex-grow-1" v-if="showScopeFilter">
        <div class="filter" :class="{ max25: $vuetify.breakpoint.smAndUp }">
          <ScopeFilter />
        </div>
      </v-slide-item>
      <v-slide-item class="flex-grow-1">
        <div class="filter" :class="{ max25: $vuetify.breakpoint.smAndUp }">
          <DateFilter />
        </div>
      </v-slide-item>

      <v-slide-item class="flex-grow-1" v-if="productCampaign">
        <div class="filter" :class="{ max25: $vuetify.breakpoint.smAndUp }">
          <ProductFilter
            :productCategories="dashboardFilterProductCategories"
            :products="dashboardFilterProducts"
            :campaign="productCampaign"
          />
        </div>
      </v-slide-item>

      <template
        v-if="hasRightUseFilters"
        v-for="mainFilter, mainFilterKey in mainFilters"
      >
        <v-slide-item
          class="flex-grow-1"
          v-show="showVoterFilter"
          v-if="$vuetify.breakpoint.smAndUp && showMainFilter(mainFilter, mainFilterKey)"
        >
          <div
            class="filter"
            :class="{ max25: $vuetify.breakpoint.smAndUp }"
            :key="mainFilterKey"
          >
            <MainFilter :title="mainFilter.filterName || mainFilter.title" :nbFilters="nbActiveByFilterKey[mainFilterKey]">
              <template #icon>
                <w-icon :icon="mainFilter.wicon" class="c-accent filter-icon" />
              </template>

              <template #content>
                <VoterStringInfoFilterContent
                  v-if="mainFilter.filterType === 'CampaignVoterKeyStringInfo'"
                  :filterOptions="mainStringFiltersOptions[mainFilterKey]"
                  :filter-key="mainFilter.filterKey"
                />
                <VoterFloatInfoFilterContent
                  v-if="mainFilter.filterType === 'CampaignVoterKeyFloatInfo'"
                  :filter-key="mainFilter.filterKey"
                />
              </template>
            </MainFilter>
          </div>
        </v-slide-item>
      </template>

      <v-slide-item
        class="flex-grow-1"
        v-show="showVoterFilter"
        v-if="hasRightUseFilters && $vuetify.breakpoint.smAndUp && (sources.length > 1 || wizvilleSources.length > 1)"
      >
        <div
          :class="{ max25: $vuetify.breakpoint.smAndUp }"
          class="filter"
        >
          <MainFilter
            :title="$t('platform_filter')"
            :nbFilters="surveyFilterSources && surveyFilterSourcesLength"
          >
            <template #icon>
              <w-icon icon="survey" class="c-accent filter-icon" />
            </template>

            <template #content>
              <SourceFilterContent
                :availableSources="sources"
                :availableWizvilleSources="wizvilleSources"
              />

            </template>
          </MainFilter>
        </div>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import ScopeFilter from './ScopeFilter'
import DateFilter from './DateFilter'
import ProductFilter from './ProductFilter'
import SourceFilterContent from './FilterContent/SourceFilterContent'
import VoterStringInfoFilterContent from './FilterContent/VoterStringInfoFilterContent'
import VoterFloatInfoFilterContent from './FilterContent/VoterFloatInfoFilterContent'
import MainFilter from './MainFilter'
import _camelCase from "lodash/camelCase"
import { mapGetters } from 'vuex'

export default {
  name: 'Filters',
  components: {
    ScopeFilter,
    DateFilter,
    ProductFilter,
    SourceFilterContent,
    VoterStringInfoFilterContent,
    VoterFloatInfoFilterContent,
    MainFilter
  },
  methods: {
    onResize() {
      this.$refs.slideGroup._data.scrollOffset = 0;
      this.$refs.slideGroup.setWidths()
    },
    showMainFilter(mainFilter, mainFilterKey) {
      if (mainFilter.filterType === 'CampaignVoterKeyFloatInfo') {
        return this.mainNumericFiltersOptions[_camelCase(mainFilter.filterKey)] === true
      } else if (mainFilter.filterType === 'CampaignVoterKeyStringInfo') {
        return this.mainStringFiltersOptions[mainFilterKey] && this.mainStringFiltersOptions[mainFilterKey].length > 0
      } else {
        return false
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentDashboardRequest',
      'currentDashboardBasedTable',
      'dashboardPlaceIds',
      'campaignVoterKeyInfoByKeyName',
      'stringInfoFilters',
      'floatInfoFilters',
      'surveyFilterSources',
      'surveyFilterSourcesLength',
      'hasRightUseFilters',
      'productCampaign',
      'dashboardFilterProductCategories',
      'dashboardFilterProducts'
    ]),
    filterableKeys() {
      return [ "civility", "customer_category", "card_status", "birthdate:", "last_name", "customer_number", "purchase_date", "delivery_date", "service_date", "code_category1", "code_category2", "products", "purchase_amount", "kind_of_product", "employee_last_name", "category", "ticket_number", "service_type" ]
    },
    filterableTypes() {
      return ['CampaignVoterKeyStringInfo', 'CampaignVoterKeyFloatInfo']
    },
    filters() {
      const filters = {}
      if (this.campaignVoterKeyInfoByKeyName) {
        for (const [ keyName, campaignVoterKeyInfo ] of Object.entries(this.campaignVoterKeyInfoByKeyName)) {
          if (
            this.filterableTypes.includes(campaignVoterKeyInfo.type) &&
            this.filterableKeys.includes(campaignVoterKeyInfo.column)
          ) {
            filters[keyName] = {
              wicon: 'user-shape-1',
              filterKey: campaignVoterKeyInfo.column,
              title: campaignVoterKeyInfo.label,
              filterName: campaignVoterKeyInfo.filterName,
              filterType: campaignVoterKeyInfo.type
            }
          }
        }
      }
      return filters
    },
    mainFilters() {
      let i = 0
      const mainFilters = {}

      if (this.mainStringFiltersOptions) {
        for (const filterKey of Object.keys(this.filters)) {
          mainFilters[filterKey] = this.filters[filterKey]
          i += 1
        }
      }

      return mainFilters
    },
    nbActiveByFilterKey() {
      const nbActiveByFilterKey = {}

      if (this.mainFilters) {
        for (const [filterKey, mainFilter] of Object.entries(this.mainFilters)) {
          const stringInfoFilterCount = (this.stringInfoFilters && this.stringInfoFilters[mainFilter.filterKey]) ?
            this.stringInfoFilters[mainFilter.filterKey].length : 0
          const floatInfoFilterCount = (this.floatInfoFilters && (this.floatInfoFilters[mainFilter.filterKey]?.min || this.floatInfoFilters[mainFilter.filterKey]?.max)) ? 1 : 0

          nbActiveByFilterKey[filterKey] =  stringInfoFilterCount || floatInfoFilterCount
        }
      }

      return nbActiveByFilterKey
    },
    sources() {
      const sources = {}

      if (this.mainStringFiltersOptions?.source) {
        var wizville_source = false
        for (const source of this.mainStringFiltersOptions.source) {
          if (['google', 'facebook', 'pages_jaunes', 'trip_advisor', 'ubereats', 'deliveroo', 'skeepers'].includes(source)) {
            sources[source] = true
          } else {
            wizville_source = true
          }
        }
        sources['wizville'] = wizville_source
      }

      return Object.keys(sources)
    },
    wizvilleSources() {
      const sources = {}

      if (this.sources.includes('wizville')) {
        for (const source of this.mainStringFiltersOptions?.source || []) {
          if (!['google', 'facebook', 'pages_jaunes', 'trip_advisor', 'deliveroo', 'ubereats', 'skeepers'].includes(source)) {
            sources[source] = true
          }
        }
      }
      return Object.keys(sources)
    },
    showVoterFilter() {
      return (this.$route.name !== 'Competitions' && this.$route.name !== 'Achievements' && this.$route.name !== 'Collaborators')
    },
    showScopeFilter() {
      return (this.$route.name !== 'Competitions' || (this.$route.name == 'Competitions' && this.$route?.params?.scope === 'perimeter' ) && this.$route.name !== 'Collaborators')
    },
    mainStringFiltersOptions() {
      return this.mainFiltersOptions?.stringFilterOptions || {}
    },
    mainNumericFiltersOptions() {
      return this.mainFiltersOptions?.numericFilterOptions || {}
    }
  },
  asyncComputed: {
    mainFiltersOptions: {
      async get() {
        let result = {}

        if (this.filters) {
          const request = this.currentDashboardRequest.select({
            [this.currentDashboardBasedTable]: [
              { string_filter_options: { params: { place_ids: this.dashboardPlaceIds, filter_keys: [...Object.values(this.filters).map(f => f.filterKey), ...['source']]} } },
              { numeric_filter_options: { params: { place_ids: this.dashboardPlaceIds, filter_keys: [ 'purchase_amount' ] } } }
            ]
          })


          result = (await this.$resolve(request)).first()
        }

        return (result || {})
      },
      default: null
    }
  },
  watch:{
    $route() {
      if (this.$refs.slideGroup) {
        this.$refs.slideGroup.setWidths()
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@theme/colors.styl'
@import '~@theme/medias.styl'

.dashboard-filters
  .filter-icon
    font-size: 12px

  .filter
    min-width: 150px

  .max25
    max-width: 25% !important

  .v-slide-group.width100
    width: 100%

  .v-slide-group__prev, .v-slide-group__next
    min-width: 30px
    max-width: 30px

  .v-slide-group__prev--disabled, .v-slide-group__next--disabled
    display: none

.mobile-filter-menu
  .dropdown-title
    max-width: 100%
.v-mobile-menu
  .v-menu__content
    left 0 !important
    height 110% !important
    width 100% !important
    min-width 100% !important
    .v-list
      height 100% !important
      max-height 100% !important

.filter-skeleton
  .v-skeleton-loader__list-item
    height 39px !important
</style>

<style lang="stylus" scoped>
@import '~@theme/constants.styl'

.dashboard-filters
  height $filters-height
  .filters-row
    flex-wrap nowrap;
    .filter
      overflow hidden
</style>

<template>
  <v-row no-gutters class="w-lateral-section-layout" >
    <v-col>
      <v-row no-gutters class="navigation">
        <v-col>
          <slot name="navigation"/>
        </v-col>
      </v-row>
      <v-row no-gutters class="section pt-0">
        <v-col cols="12" :lg="fullScreenMode ? 12 : 3" :md="fullScreenMode ? 12 : 3" sm="12">
          <v-row no-gutters class="left-cols">
            <slot name="lateral-section"/>
          </v-row>
        </v-col>
        <v-col cols="12" :lg="fullScreenMode ? 12 : 9" :md="fullScreenMode ? 12 : 9" sm="12">
          <v-row no-gutters class="right-cols">
            <slot name="main-section" />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "WLateralSectionLayout",
    props: [ 'fullScreenMode' ]
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/medias.styl'
  @import '~@theme/constants.styl'

  .w-col
    padding-bottom: 15px !important
    padding-right: 15px !important
    padding-left: 15px !important

  +forBreakpoints(desktop, tablet)
    .left-cols
      position: sticky
      position:-webkit-sticky
      top: "calc(%s + %s + %s + 10px)" % ($filters-height $app-bar-height $secondary-menu-height)

      .w-col
        padding-left: 15px !important

    .right-cols
      .w-col
        padding-left: 0px !important

  +forBreakpoints(mobile)
    .right-cols
      .w-col
        padding-left: 15px !important

  +forBreakpoints(mobile)
    .left-cols
      .w-col:nth-child(even)
        padding-left: 0px !important

    .section
      padding-top: 15px

  +forBreakpoints(desktop_only)
    .statistics
      padding-top: $filters-height

    .left-cols
      top: "calc(%s + %s + %s + 10px)" % ($filters-height $app-bar-height $secondary-menu-height) !important

  +forBreakpoints(tablet)
    .left-cols
      .w-col:nth-child(even)
        padding-left: 0px !important
</style>

import ChartsDefaultOptionsMixin from './charts_default_options_mixin'
import deepmerge from '@shared/helpers/deepmerge';

export default {
  mixins: [
    ChartsDefaultOptionsMixin
  ],
  computed: {
    columnChartsDefaultParametersMixin_chartDefaultOptions() {
      const localOptions = {
        chart: {
          type: 'column',
          height: "400px",
        },
        legend: {
          enabled: true
        },
        xAxis: {
          gridLineWidth: 0
        },
        yAxis: {
          enabled: false,
          stackLabels: {
            style: {
              color: '#666666'
            }
          }
        },
        tooltip: {
          enabled: false
        }
      }

      return deepmerge(this.chartsDefaultOptionsMixin_sharedDefaultOptions, localOptions)
    }
  }
}
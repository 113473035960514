<template>
  <WStatsWidget
    class="widget-verbatim-topic-ranking"
    v-if="!fullScreenMode || (filteredRanking && filteredRanking.length > 0)"
    :title="title"
    :loading="loading"
    :empty="filteredRanking && filteredRanking.length == 0"
    :emptyLabel="emptyLabel"
    :height="fullScreenMode ? undefined : '380px'"
    contentWidth="100%"
    :justifyCenter="false"
    :cols="6"
  >
    <template #title>
      <div class="d-flex">
        <a v-if="activeRanking" @click="activeRanking = null">
          <v-icon small style="position: relative; bottom: 1px; padding-right: 5px">mdi-keyboard-backspace</v-icon>
          <span>{{ $t('competitors_widget_ranking_title') }} ({{ activeRanking.name }})</span>
        </a>

        <a v-else-if="activeVerbatim" @click="activeVerbatim = null">
          <v-icon small style="position: relative; bottom: 1px; padding-right: 5px">mdi-keyboard-backspace</v-icon>
          <span> {{ $t('reporting_verbatim')}} ({{ activeVerbatim.name }})</span>
        </a>

        <template v-else>
          <span class="pr-1">{{ title }}</span><WTooltip :message="message" />
        </template>
      </div>
    </template>

    <template #subtitle>
      {{ dashboardFilterDates.text }}
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="f-12 c-lightgrey" small>mdi-information-outline</v-icon>
        </template>
        <span>{{ $t('reviewsNextMonthDisplayTooltip') }}</span>
      </v-tooltip>
    </template>

    <template #content>
      <div>
        <ZoomedTopicRankingTable
          v-if="activeRanking"
          :topicRanking="activeRanking"
        />
        <ZoomedTopicVerbatimTable
          v-else-if="activeVerbatim"
          :scope="scope"
          :currentItem="currentItem"
          :topicId="activeVerbatim.id"
          :strengths="strengths"
          :weaknesses="weaknesses"
        />
        <TopicRankingTable
          v-else
          :ranking="filteredRanking"
          @showRankingTable="showRankingTable"
          @showVerbatimTable="showVerbatimTable"
        />
      </div>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex';
  import TopicRankingTable from "./TopicRankingTable"
  import ZoomedTopicRankingTable from "../ZoomedTopicRankingTable"
  import ZoomedTopicVerbatimTable from "../ZoomedTopicVerbatimTable"

  export default {
    name: "VerbatimTopicRanking",
    props: [ 'strengths', 'weaknesses', 'ranking', 'loading', 'currentItem', 'scope' ],
    data() {
      return {
        activeRanking: null,
        activeVerbatim: null
      }
    },
    components: {
      TopicRankingTable,
      ZoomedTopicRankingTable,
      ZoomedTopicVerbatimTable
    },
    computed: {
      ...mapGetters([
        'dashboardFilterDates',
        'fullScreenMode'
      ]),
      emptyLabel() {
        if (this.strengths && this.weaknesses) {
          return this.$t('no_data_label')
        } else if (this.strengths) {
          return this.$t('no_competitive_strength_found')
        } else if (this.weaknesses) {
          return this.$t('no_competitive_weakness_found')
        }
      },
      filteredRanking() {
        let result = []

        if (this.strengths) {
          result = this.ranking?.filter(item => item.current?.top && item?.current?.top > 50.0)
        }

        if (this.weaknesses) {
          result = this.ranking?.filter(item => item.current?.top && item.current?.top <= 50.0)
        }

        return result
      },
      title() {
        let title = ""

        if (this.strengths) {
          title = `${this.$t('strengths')}`
        }

        if (this.weaknesses) {
          title = `${title}${this.$t('weaknesses')}`
        }

        if (this.currentItem?.isUserItem === false) {
          title = `${title} - ${this.currentItem.name}`
        }

        return title
      },
      message() {
        let message = ""

        if (this.strengths) {
          message = `${this.$t('competitive_strength_tooltip')}`
        }

        if (this.weaknesses) {
          message = `${message} ${this.$t('competitive_weakness_tooltip')}`
        }

        return message
      }
    },
    methods: {
      showRankingTable(item) {
        this.activeRanking = item
      },
      showVerbatimTable(item) {
        this.activeVerbatim = item
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>

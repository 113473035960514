<template>
  <div class="advanced-search">
    <WAutocomplete
      v-model="selectedPrompt"
      :items="allPrompts"
      @change="applyPrompt"
      label="Modèles de prompt"
    />

    <v-textarea
      class="pa-3"
      v-model="prompt"
      hide-details
    />

    <v-row no-gutters>
      <v-col class="text-right py-0 my-1 px-3">
        <v-btn
          @click="search"
          :disabled="prompt.length == 0"
          :ripple="false"
          :loading="searching"
          color="secondary"
        >
          <v-icon left dark>mdi-robot-happy-outline</v-icon>
          Demander à l'IA
        </v-btn>
      </v-col>
    </v-row>

    <div class="pa-3">  
      <v-alert
        class="f-14"
        color="primary"
        border="left"
        elevation="2"
        colored-border
        icon="mdi-robot-happy"
      >
        <div v-for="resultLine in resultLines">
          {{ resultLine }}  
        </div>
        <div v-if="cost && !$production">
          (~{{ cost }}$<span v-if="requests > 1">, {{ requests }} requêtes</span>)
        </div>
        
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvandedSearch",
  props: [ 'filteredVoterRequestBase' ],
  data() {
    return {
      prompt: "",
      cost: null,
      requests: 0,
      searching: false,
      advandedPrompt: false,
      selectedPrompt: null,
      limit: 10000,
      resultLines: ["Bonjour, je suis là pour vous aider à comprendre vos verbatims, je suis limité à 1000 verbatims pour l'instant"],
      allPrompts: [
        { text: "Résumé du verbatim", value: "Fais moi un résumé en 5 points maximum" },
        { text: "Motifs d'insatisfaction", value: "Fais moi un résumé des motifs d'insatisfaction par importance en 5 points maximum" },
        { text: "Motifs de satisfaction", value: "Fais moi un résumé des motifs de satisfaction par importance en 5 points maximum" },
      ]
    }
  },
  methods: {
    applyPrompt(value) {
      this.prompt = value
    },
    async search() {
      this.searching = true
      const promptedVoterRequest = this.filteredVoterRequestBase.limit(1000).where({ 
        main_verbatim_id: { 'is_not_null': {} } 
      }).select({
        voters: [
          { prompted: { batch_params: { prompt: this.prompt } } }
        ]
      })

      const result = (await this.$resolve(promptedVoterRequest)).data.voters[0]
      const prompted = result.prompted
      const content = prompted.content
      const cost = prompted.cost
      const requests = prompted.requests
      
      this.resultLines = content.split("\n")
      this.cost = cost
      this.requests = requests
      this.searching = false
    }
  }
}
</script>

<template>
  <WAutocomplete
    v-if="campaignSmartBenchmarkPreferences.length > 1"
    :value="campaignSmartBenchmarkPreferenceId"
    :items="campaignSmartBenchmarkPreferences"
    itemText="name"
    itemValue="id"
    backgroundColor="white"
    rounded
    dense
    flat
    :fontSize="13"
    @change="onCampaignSmartBenchmarkPreferenceIdChanged"
   />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "CampaignSmartBenchmarkPreferenceAutocomplete",
  props: {
    campaignSmartBenchmarkPreferenceId: { required: false },
    campaignSmartBenchmarkPreferences: { required: true }
  },
  computed: {
    ...mapGetters([
    ])
  },
  methods: {
    onCampaignSmartBenchmarkPreferenceIdChanged(id) {
      this.$emit('campaignSmartBenchmarkPreferenceIdChanged', id)
    }
  }
}
</script>

import scope from './filters/scope'
import dates from './filters/dates'
import survey from './filters/survey'
import product from './filters/product'
import voterStringInfo from './filters/voter_string_info'
import voterFloatInfo from './filters/voter_float_info'
import BasedQuery from '@api/based/basedQuery'

const filters = {
  state: () => ({
    ready: false,
  }),
  modules: {
    scope,
    dates,
    survey,
    product,
    voterStringInfo,
    voterFloatInfo
  },
  getters: {
    dashboardFilters(state, getters) {
      if (state.ready) {
        return {
          dashboard_filters: {
            scope: getters.dashboardFilterScope,
            dates: getters.dashboardFilterDates,
            voterStringInfoFilters: getters.dashboardVoterStringInfoFilters,
            voterFloatInfoFilters: getters.dashboardVoterFloatInfoFilters,
            product: getters.dashboardFilterProduct,
            survey: { sources: getters.surveyFilterSources }
          }
        }
      } else {
        return null
      }
    },
    dashboardFilterBase64(state, getters) {
      const filters = getters.dashboardFilters

      if (filters) {
        return btoa(unescape(encodeURIComponent(JSON.stringify(filters.dashboard_filters))))
      } else {
        return filters
      }
    },
    brandDashboardFilterRequest(state, getters) {
      let request = new BasedQuery().where({
        campaign_dashboards_dashboard_id: getters.currentDashboard.id,
        campaign_dashboards_dashboard_type: getters.currentDashboard.type,
      })

      if (getters.sqlDateBegin || getters.sqlDateEnd) {
        request = request.dateBetween(
          getters.sqlDateBegin,
          getters.sqlDateEnd
        )
      }

      if (getters.dashboardFilterSurveySources) {
        request = request.where({
          source: getters.dashboardFilterSurveySources,
        })
      }

      if (getters.dashboardVoterStringInfoFilters) {
        request = request.where({
          voter_string_info: {
            voter_string_info_filters: {
              string_info_filters: getters.dashboardVoterStringInfoFilters
            }
          }
        })
      }

      if (getters.dashboardVoterFloatInfoFilters) {
        request = request.where({
          voter_float_info: {
            voter_float_info_filters: {
              float_info_filters: getters.dashboardVoterFloatInfoFilters
            }
          }
        })
      }

      if (getters.dashboardFilterProductCategoryIds) {
        request = request.where({
          product_category_id: getters.dashboardFilterProductCategoryIds
        })
      }

      if (getters.dashboardFilterProductIds) {
        request = request.where({
          product_id: getters.dashboardFilterProductIds
        })
      }

      return request
    },
    dashboardFilterRequest(state, getters) {
      let request = getters.brandDashboardFilterRequest

      if (getters.placeIds?.length) {
        request = request.where({
          place_campaigns_place_id: getters.placeIds,
        })
      }

      return request
    },
    lrmDashboardFilterRequest(state, getters) {
      let request = new BasedQuery().where({
        campaign_dashboards_dashboard_id: getters.currentDashboard.id,
        campaign_dashboards_dashboard_type: getters.currentDashboard.type,
        campaign_dashboards_campaign_id: getters.currentDashboardLrmCampaign.id
      })

      return request
    },
    lmPlaceReviewFilteredRequest(state, getters) {
      let request = new BasedQuery().where({
        lm_place_id: getters.lmGmbPlaceIdsScope,
      })

      if (
        getters.sqlDateBegin || getters.sqlDateEnd
      ) {
        request = request.dateBetween(
          getters.sqlDateBegin,
          getters.sqlDateEnd
        )
      }

      return request
    },
    dashboardFilterReady(state, getters) {
      return state.ready && getters.dashboardFilterDates
    },
  },
  mutations: {
    setDashboardFilterReady(state) {
      state.ready = true
    },
  },
  actions: {
    async setDashboardFilters(context, filters) {
      await context.dispatch('setDashboardScopeFilters', filters)
      await context.dispatch('setDashboardDatesFilters', filters)
      await context.dispatch('setDashboardVoterStringInfoFilters', filters)
      await context.dispatch('setDashboardVoterFloatInfoFilters', filters)
      await context.dispatch('setDashboardSurveyFilters', filters)
      await context.dispatch('setDashboardProductFilters', filters)

      context.commit('setDashboardFilterReady')
    },
  },
}

export default filters

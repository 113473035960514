<template>
  <div class="w-timeline-chart" :style="chartStyle">
    <h2
      v-if="!!title"
      class="f-18"
    >
      {{ title }}
    </h2>

    <v-skeleton-loader v-if="isFirstLoading" type="image,  table-row"/>
    <div v-else>
      <div class="chart-timeline-loading" v-if="isLoading">
        <v-progress-linear indeterminate color="#144C6E"></v-progress-linear>
      </div>
      <div class="chart-container" :style="{ height: this.height }">
        <div class="chart">
          <Chart :options="{ ...chartMergedOptions, series: chartSeries }" ref="chart" :width="100" :height="100" />
        </div>
      </div>
      <div style="margin-top: -46px">
        <WTimeNavigation
          :options="options"
          :refreshData="refreshData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue"
import ExportingHighcharts from "highcharts/modules/exporting"
import Highcharts from "highcharts";
import dayjs from "dayjs"
import { i18n } from '@i18n/setup'
import _debounce from "lodash/debounce"
import _values from "lodash/values"
import _keys from "lodash/keys"
import _filter from "lodash/filter"
import _cloneDeep from "lodash/cloneDeep"
import chartToPng from "@shared/helpers/chart-to-png"
import Pdf from '@shared/helpers/exportToPdf/pdf'
import TooltipableChartMixin from '@statistics/shared/ChartsFeatures/tooltipable_chart_mixin'
import TimelineChartsDefaultOptionsMixin from '@statistics/shared/ChartsDefaultOptions/timeline_charts_default_options_mixin'
import deepmerge from '@shared/helpers/deepmerge'

ExportingHighcharts(Highcharts);

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const DEFAULT_COLORS = ['#FFB800', '#9FBAB8', '#B27B59', '#DB3572', '#88C788', '#BA0022', '#2E1C4D', '#C48754', '#4EBDB7', '#4F64B6', '#C1CC75', '#DBA951', '#B96785', '#874B8B', '#4BAEE4', '#6F63BA', '#2699A6', '#9D8B52', '#7DBD96', '#278DDA', '#B473BB', '#98BBB8', '#98A255', '#E8DF81', '#BD7953', '#E2BCA4']

export default {
  name: "WTimelineChart",
  mixins: [
    TooltipableChartMixin,
    TimelineChartsDefaultOptionsMixin
  ],
  data() {
    return {
      isLoading: false,
      isFirstLoading: true,
      series: [],
      categories: [],
      currentTime: Date.now(),
      history: {},
      chartStyle: "padding-bottom: 60px !important",
      periodSelected: [],
      options: {}
    }
  },
  components: {
    Chart
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    defaultOptions: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    serieColors: {
      type: Array,
      required: false,
      default: () => DEFAULT_COLORS
    },
    height: {
      type: String,
      required: false,
      default: "400px"
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#fff"
    },
    colorBorders: {
      type: String,
      required: false,
      default: "#deefee"
    },
    colorChartBorders: {
      type: String,
      required: false,
      default: "transparent"
    },
    colorPeriod: {
      type: String,
      required: false,
      default: "#E5EAF2"
    },
    colorMarkerMainLine: {
      type: Array,
      required: false,
      default: () => DEFAULT_COLORS
    },
    connectNulls: {
      type: Boolean,
      required: false,
      default: true
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'YYYY-MM-DD'
    },
    type: {
      type: String,
      required: false,
      default: 'spline'
    },
    decimals: {
      type: Number,
      required: false,
      default: 1
    },
    quarterOffset: {
      type: Number,
      required: false,
      default: 0
    }
  },
  mounted: function() {
    this.refreshOptions()
    this.refreshData()
  },
  computed: {
    chartSeries(){
      const _this = this

      return this.series.map ( (s, index) => {
        const serieColor = this.serieColors[index] || DEFAULT_COLORS[index]

        return {
          ...s,
          dashStyle: "Solid",
          color: serieColor,
          dataLabels : {
            enabled : !this.options.markerEnabled,
            color: this.options.markerColor || this.colorMarkerMainLine[index],
            formatter : function() {
              return this.y?.toFixed(_this.decimals)
            },
            style: {
              textOutline: 0,
              fontWeight: 'bold',
              fontSize: '12px'
            }
          },
          fillColor: {
            linearGradient: [0, 0, 0, 250],
            stops: [
                [0, Highcharts.color(serieColor)
                        .setOpacity(0.4).get('rgba')],
                [
                    1,
                    Highcharts.color(serieColor)
                        .setOpacity(0).get('rgba')
                ]
            ]
          }
        }
      })
    },
    chartMergedOptions() {
      const chartLocalOptions = {
        xAxis: {
          categories: this.categories
        },
        yAxis: {
          min: this.min,
          max: this.max
        }
      }

      return deepmerge.all([this.timelineChartsDefaultParametersMixin_chartDefaultOptions, chartLocalOptions, this.chartOptions])
    },
    min() {
      let min = this.options.min

      if (this.options.chartZoom) {
        for (const serie of this.series) {
          for (const dataPoint of serie.data) {
            if (dataPoint) {
              const zoomedDataPoint = (dataPoint - this.options.chartZoom)

              if (this.options.min && zoomedDataPoint < this.options.min) {
                return this.options.min
              } else if (min && zoomedDataPoint < min) {
                min =  Math.floor(zoomedDataPoint)
              } else if (!min) {
                min =  Math.floor(zoomedDataPoint)
              }
            }
          }
        }
      }

      return (min ? min : undefined)
    },
    max() {
      let max = this.options.max

      if (this.options.chartZoom) {
        for (const serie of this.series) {
          for (const dataPoint of serie.data) {
            if (dataPoint) {
              const zoomedDataPoint = dataPoint + this.options.chartZoom

              if (this.options.max && zoomedDataPoint > this.options.max) {
                return this.options.max
              } else if (max && zoomedDataPoint > max) {
                 max = Math.ceil(zoomedDataPoint)
              } else if (!max) {
                max = Math.ceil(zoomedDataPoint)
              }
            }
          }
        }
      }

      return (max ? max : undefined)
    }
  },
  methods: {
    refreshOptions() {
      const _this = this
      this.options = this.defaultOptions;
      this.options.legendEnabled = !(this.options.legendEnabled === false)
      this.options.markerEnabled = !(this.options.markerEnabled === false)
      this.options.dataPointColor = this.options.dataPointColor || null
      this.options.maxDate = this.options.maxDate || dayjs()
      this.options.minDate = this.options.minDate || dayjs().subtract(100, 'years')
      if(typeof(this.options.maxDate) === "string") this.options.maxDate = dayjs(this.options.maxDate, this.dateFormat)
      if(typeof(this.options.minDate) === "string") this.options.minDate = dayjs(this.options.minDate, this.dateFormat)

      this.options.tickUnit = this.options.tickUnit || "day"
      this.options.unit = this.options.unit || this.options.tickUnit + "s"
      this.options.tickNumber = this.options.tickNumber || 1

      this.options.tooltipFields = this.options.tooltipFields || []
      this.options.valueKey = this.options.valueKey || "avgScore"

      var period_number = 8

      switch (this.options.unit) {
        case 'days':
          period_number = 7;
          break;
        case 'weeks':
          period_number = 4;
          break;
        case 'months':
          period_number = 6;
          break;
        case 'years':
          period_number = 4;
          break;
      }

      this.options.periodNumber = this.options.periodNumber || period_number
      this.options.toTimeSeriesOptions = this.options.toTimeSeriesOptions || {}
      this.options.toTimeSeriesOptions.limit = this.options.toTimeSeriesOptions.limit || this.options.periodNumber
      this.options.to = this.options.to || dayjs()

      if(typeof(this.options.to) === "string") this.options.to = dayjs(this.options.to, this.dateFormat)

      this.options.from = this.options.from || this.options.to.subtract(this.options.periodNumber, this.options.unit)
      if(typeof(this.options.from) === "string") this.options.from = dayjs(this.options.to, this.dateFormat)


      if(this.options.unit == "weeks") {
        this.options.from = this.options.from.endOf('week').add(1, 'day').startOf('day')
        this.options.to = this.options.to.endOf('week').endOf('day')
      }

      if(this.options.unit == "quarters") {
        this.options.from = this.options.from.endOf('quarter').add(1, 'day').startOf('quarter').add(this.quarterOffset, "month")
        this.options.to = this.options.to.endOf('quarter').add(this.quarterOffset, "month")
      }

      if(this.options.unit == "months") {
        this.options.from = this.options.from.endOf('month').add(1, 'day').startOf('month')
        this.options.to = this.options.to.endOf('month')
      }

      if(this.options.unit == "years") {
        this.options.from = this.options.from.endOf('year').add(1, 'day').startOf('year')
        this.options.to = this.options.to.endOf('year')
      }

      if(this.options.toTimeSeriesOptions) {
        this.options.toTimeSeriesOptions.limit = this.options.toTimeSeriesOptions.limit || this.options.periodNumber
      }
    },
    dataChanged(){
      this.refreshOptions()
    },
    exportToPng(fileName, title, subtitle) {
      this.$store.dispatch("notifyInfo");
      const chart = this.$refs.chart.chart;

      chartToPng(chart, { width: 700, fileName, title, subtitle });
    },
    async exportToPdf(fileName, title, subTitle) {
      this.$store.dispatch("notifyInfo");

      const chart = this.$refs.chart.chart;
      const pdf = new Pdf({
        defaultBodyMargin: { left: 40, top: 40 }
      });

      await pdf.addPage()

      const element = document.getElementById(this.exportElementId);

      if (title) {
        await pdf.addRow({}, async (row) => {
          await row.addCol({ width: '12' }, async (col) => {
            await col.addText(title, { color: "#212121", font: { style: 'semi-bold' } });
          });
        });
      }
      if (subTitle) {
        await pdf.addRow({}, async (row) => {
          await row.addCol({ width: '12' }, async (col) => {
            await col.addText(subTitle, { fontSize: 6, color: "#666", font: { style: 'semi-bold' } });
          });
        });
      }
      await pdf.addRow({ marginTop: 20 }, async (row) => {
        await row.addCol({ width: '12' }, async (col) => {
          await col.addChart(chart, { width: 700 });
        });
      });

      pdf.save(fileName);
    },
    async getData() {
      var _this = this
      var opts = this.options
      var key = opts.from.format("YYYY-MM-DD") + "-" + opts.to.format("YYYY-MM-DD")

      if(opts && opts.toTimeSeriesOptions) {
        opts.toTimeSeriesOptions.date_begin = opts.from.subtract(1, _this.options.tickUnit).format("YYYY-MM-DD")
        opts.toTimeSeriesOptions.date_end = opts.to.add(1, _this.options.tickUnit).format("YYYY-MM-DD")
        opts.toTimeSeriesOptions.limit += 2
        if(!opts.toTimeSeriesOptions.cumulative) {
          opts.request = opts.request.dateBetween(opts.toTimeSeriesOptions.date_begin, opts.toTimeSeriesOptions.date_end)
        }
      }
        const response = await opts.request.resolve(
          "WTimelineChart",
          {
            tickNumber: opts.tickNumber,
            tickUnit: opts.tickUnit,
            timeSerieParams: opts.toTimeSeriesOptions
          },
          "time_series"
        )
        this.history[key] = response.data

      return _cloneDeep(this.history[key])
    },

    refreshData: _debounce(function() {
      var _this = this
      _this.period_from_selector = 1000
      _this.period_to_selector = 1000
      if(!this.isFirstLoading) this.isLoading = true

      _this.$emit('loadingStatusChanged', true);

      this.getData().then(function(data) {
        if((data?.series?.length || 0) == 0) {
          data.series = [
            {
              name: i18n.t("w_timeline_chart_no_data"),
              data: [...Array(data?.labels?.length || 0).keys()].map(i => 0)
            }
          ]
        }
        let series = data.series

        series.forEach(function(serie) {
          serie.rawData = serie.data

          serie.data = serie.data.map((dataPoint) => {
            if (dataPoint && typeof(dataPoint) === 'object') {
              return dataPoint[_this.options.valueKey]
            }

            return dataPoint
          })

          serie.valueKey = _this.options.valueKey
        })

        if (_this.series.length > 0 && _this.series[0]?.name !== i18n.t("w_timeline_chart_no_data")) {
          _this.series.forEach(function(serie) {
            serie.data = []

            let same_serie = _filter(series, function(s) { return s.name == serie.name })[0]
            if(same_serie) {
              serie.data = same_serie.data
              serie.rawData = same_serie.rawData
            }
          })
        }
        else {
          _this.series = series
        }

        if (data?.labels) {
          _this.categories = data.labels.map(function(label) { return _values(label)[0] })
          _this.raw_categories = data.labels.map(function(label) { return _keys(label)[0] })
        } else {
          data.labels = []
        }

        // Define period selection
        if(_this.period_to_selector != 1000 && _this.period_to_selector > _this.options.periodNumber) _this.period_to_selector = _this.options.periodNumber
        if(_this.period_from_selector != 1000 && _this.period_from_selector < 1) _this.period_from_selector = 1
        if(_this.options.unit == "quarters") _this.period_to_selector = _this.period_from_selector

        _this.isLoading = false
        _this.isFirstLoading = false

        _this.$emit('loadingStatusChanged', false);
      })
    }, 300, { leading: true })
  },
  watch: {
    defaultOptions: {
      handler: 'dataChanged'
    },
  }
}
</script>

<style lang="stylus">
  .chart-container
    position: relative
    width: 100%

    .chart
      position: absolute
      width: 100%

  .chart-timeline-loading
    margin-right: 1px

  .tooltip-bottom, .tooltip-top
    border-radius: 4px
    position: relative
    background-color: #fff
    filter:drop-shadow(0 1px 2px rgba(0,0,0,.3))
    padding: 10px
  
  .tooltip-bottom:after
    content: ""
    position: absolute
    top: -12px
    right: calc(50% - 6px)
    border-width: 12px 0px 12px 12px
    border-style: solid
    transform: rotate(-90deg)
    border-color: transparent transparent transparent #fff
    display: block
    width: 0
    height: 0
  
  .tooltip-top:after
    content: ""
    position: absolute
    bottom: -12px
    right: calc(50% - 6px)
    border-width: 12px 0px 12px 12px
    border-style: solid
    transform: rotate(90deg)
    border-color: transparent transparent transparent #fff
    display: block
    width: 0
    height: 0

</style>
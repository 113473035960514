<template>
  <div class="competitivity-topic-ranking-table">
    <WInfiniteScroll :rows="formatedRows" :headers="headers" :fullScreenMode="fullScreenMode">
      <template #item.priority="{ value }">
         {{ value }}
      </template>

      <template #item.weight="{ value }">
        <span v-if="value >= 1">{{ value | round }}%</span>
        <span v-else>{{ belowOnePercentLabel }}</span>

      </template>

      <template #item.competitivity="{ item, value }">
         <WRateBar
          :label="`${item.current.competitivity < 1.0 ? '<1' : Math.round(item.current.competitivity)}`"
          :value="maxCompetitivity && maxCompetitivity > 0 ? item.current.competitivity / maxCompetitivity * 100 : 0" :barColor="$colors.gold"
         />
      </template>

      <template #item.targetedScore="{ item, value }">
        <div class="fs-14" v-if="scoreToReach(item)">{{ scoreToReach(item) | round2 }}<v-icon small color="gold" class="bottom_1px">mdi-star</v-icon></div>
        <div class="f-12" v-if="currentScore(item)">{{ $t('current') }} {{ currentScore(item) | round2 }}</div>
      </template>

      <template #item.actions="{ item }">
        <v-icon class="c-lightgrey cursor" @click="$emit('showRankingTable', item)" small>mdi-format-list-numbered</v-icon>
        <v-icon class="c-lightgrey ml-2 cursor" @click="$emit('showVerbatimTable', item)"  small>mdi-comment-outline</v-icon>
      </template>
    </WInfiniteScroll>
  </div>
</template>

<script>
  import _sortBy from 'lodash/sortBy'
  import { mapGetters } from 'vuex';

  export default {
    name: "CompetitivityTopicRankingTable",
    props: ['ranking'],
    data() {
      return {
        belowOnePercentLabel: '<1%'
      }
    },
    computed: {
      ...mapGetters([
        'fullScreenMode'
      ]),
      headers() {
        return [
          {
            name: 'priority',
            title: this.$t('priority'),
            width: "70px",
            class: [ 'flex-shrink-0', 'text-center' ]
          },
          {
            name: 'name',
            title: this.$t('topic'),
            width: "150px",
            class: [ 'flex-shrink-0', 'text-left' ]
          },
          {
            name: 'weight',
            title: this.$t('expectations'),
            width: '100px',
            class: [ 'flex-shrink-0', 'text-left', 'hidden-md-and-down' ]
          },
          {
            name: 'competitivity',
            title: this.$t('competitivity_to_improve'),
            class: [ 'flex-grow-1', 'text-left' ]
          },
          {
            name: 'targetedScore',
            title: this.$t('score_to_reach'),
            width: '110px',
            class: [ 'flex-shrink-0', 'text-left', 'd-none d-sm-block']
          },
          {
            title: '',
            width: "60px",
            name: 'actions'
          }
        ]
      },
      rows() {
        return this.ranking?.filter(item => item.current?.competitivity)
      },
      formatedRows() {
        return _sortBy(this.rows, item => -item.current.competitivity)?.map((item, index) => ({
          ...item,
          priority: index + 1
        }))
      },
      maxCompetitivity() {
        return Math.max(...this.rows.map(item => item.current.competitivity))
      }
    },
    methods: {
      currentScore(item) {
        return item.user?.id == item.current?.id ? item.current?.avgScore : item?.user?.avgScore
      },
      scoreToReach(item) {
        return item.user?.id == item.current?.id ? item.scoreSpread?.max?.avgScore : item?.current?.avgScore
      }
    }
  }
</script>

<template>
  <WAutocomplete
    v-if="scopes.length > 1"
    :value="scope"
    :items="scopes"
    backgroundColor="white"
    rounded
    dense
    flat
    :fontSize="13"
    @change="onScopeChanged"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "CountryAutocomplete",
  props: {
    scope: { required: false }
  },
  computed: {
    ...mapGetters([
      'currentDashboard',
      'placeIds'
    ]),
    scopes() {
      const list = (this.currentDashboard.lmCountryCodes || [])
      const countries = this.$sortByAlpha(list.map((item) => {
        return {
          text: this.$t(`countries.${item}`),
          value: `country_${item}`
        }
      }), 'text')

      if (this.currentDashboard.lmCountryCodes.length > 1) {
        return [
          {
            text: this.$t('widget_settings_competitor_scope_all_countries_title'),
            value: 'country_all'
          },
          ...countries
        ]
      } else {
        return countries
      }
    }
  },
  methods: {
    onScopeChanged(scope) {
      this.$emit('scopeChanged', scope)
    }
  }
}
</script>

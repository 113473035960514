<template>
  <div
    class="mode-navigation pl-sm-1"
  >
    <w-nav-tabs
      :router-links-properties="routerLinksProperties"
      scrollable
      :replaceTitle="false"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default{
    name: "CompetitionsNavigation",
    props: ['campaignSmartBenchmarkPreferences'],
    computed: {
      ...mapGetters([
        'isDemoUser',
        'currentAdmin',
        'smartBenchmarkEnabled',
        'currentDashboardCampaignIds',
        'currentDashboard',
        'isDashboardMono',
        'defaultcurrentItem',
        'smartBenchmarkEnabled'
      ]),
      routerLinksProperties() {
        let routerLinksProperties = []

        const period = ['genesis', 'period'].includes(
          this.$route?.params?.periodType || 'period'
        ) ? this.$route?.params?.periodType : 'period'

        if (this.currentDashboard.preferences.isLrmEligible) {
          routerLinksProperties.push(
            this.modeNavigation(
              this.$t('lm_places'),
              'local',
              'perimeter',
              period,
              'default'
            )
          )

          if (this.currentDashboard.preferences.competitorMode === 'national') {
            routerLinksProperties.push(
              this.modeNavigation(
                this.$t('competitors_filter_national'),
                'national',
                'perimeter',
                period,
                'default'
              )
            )
          }
        }
        return routerLinksProperties
      }
    },
    methods: {
      modeNavigation(title, mode, scope, periodType, currentItemId) {
        let childNav

        // if (this.smartBenchmarkEnabled) {
        //   if (this.campaignSmartBenchmarkPreferences.length > 1) {
        //     childNav = this.campaignSmartBenchmarkPreferences.map((campaignSmartBenchmarkPreference) => {
        //       return this.link(title, mode, scope, periodType, currentItemId, campaignSmartBenchmarkPreference)
        //     })
        //   }

        //   return this.link(title, mode, scope, periodType, currentItemId, null, childNav)
        // }

        return this.link(title, mode, scope, periodType, currentItemId)
      },
      link(title, mode, scope, periodType, currentItemId, campaignSmartBenchmarkPreference, childNav) {
        const active = mode === 'lm_brands' ?
          this.$route.params.mode === mode && this.$route.params.periodType === periodType :
          this.$route.params.mode === mode

        const link = {
          title: title,
          className: `${mode}-${scope}-${periodType}-${currentItemId}-competitors-link`,
          active: active,
          route: {
            name: 'Competitions',
            params: {
              mode: mode,
              scope: scope,
              periodType: periodType,
              currentItemId: currentItemId
            }
          }
        }

        // if (this.smartBenchmarkEnabled) {
        //   if (childNav) {
        //     link.childNav = childNav
        //   }

        //   if (campaignSmartBenchmarkPreference) {
        //     link.title = campaignSmartBenchmarkPreference.name
        //     link.className = `${mode}-${scope}-${periodType}-${currentItemId}-${campaignSmartBenchmarkPreference.id}-competitors-link`
        //     link.default = campaignSmartBenchmarkPreference.isDefault
        //     link.route.params = {
        //       ...link.route.params,
        //       ...{ campaignSmartBenchmarkPreferenceId: String(campaignSmartBenchmarkPreference.id) }
        //     }
        //   }
        // }

        return link
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>

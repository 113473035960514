import dayjs from 'dayjs'

const HISTORICAL_DATA_LIMIT_IN_YEARS = 20

export default {
  computed: {
    timeNavigationMixin_periodNumber() {
      if (this.dashboardFilterDates.value.key == 'last_12_months') return 12

      const unitMapping = {
        day: 7,
        week: 6,
        month: 12,
        year: 4,
      }

      return unitMapping[this.dashboardFilterUnit] ?? 8
    },
    timeNavigationMixin_maxDate() {
      return dayjs().endOf(this.dashboardFilterUnit)
    },
    timeNavigationMixin_minDate() {
      return dayjs().startOf(this.dashboardFilterUnit).subtract(HISTORICAL_DATA_LIMIT_IN_YEARS, 'years')
    },
    timeNavigationMixin_defaultFromDate() {
      // Offset is set to 0 for filters such as "current week", and set to 1 for filters such as "last week"
      const offset = this.dashboardFilterDates.value.offset || 0
      return dayjs().startOf(this.dashboardFilterUnit).subtract(this.timeNavigationMixin_periodNumber + offset, this.dashboardFilterUnit)
    },
    timeNavigationMixin_defaultToDate() {
      // Offset is set to 0 for filters such as "current week", and set to 1 for filters such as "last week"
      const offset = this.dashboardFilterDates.value.offset || 0
      return dayjs().endOf(this.dashboardFilterUnit).subtract(offset, this.dashboardFilterUnit)
    },
    timeNavigationMixin_tickLimit() {
      const result = {
        day: 6,
        week: 6,
        month: 12,
        quarter: 6,
        year: 6
      }[this.dashboardFilterUnit]

      if (result == null)
        throw new Error(`Invalid dashboard filter unit: ${this.dashboardFilterUnit}`)

      return result
    }
  }
}

<template>
  <div class="expectation-topic-ranking-table">
    <WInfiniteScroll :rows="formatedRows" :headers="headers" :fullScreenMode="fullScreenMode">
      <template #item.priority="{ value }">
         {{ value }}
      </template>

      <template #item.weight="{ item, value }">
         <WRateBar
          :label="`${value < 1.0 ? '<1' : Math.round(value)}`"
          :value="maxWeight && maxWeight > 0 ? value / maxWeight * 100 : 0" :barColor="$colors.gold"
         />
      </template>

      <template #item.actions="{ item }">
        <v-icon class="c-lightgrey cursor" @click="$emit('showRankingTable', item)" small>mdi-format-list-numbered</v-icon>
        <v-icon class="c-lightgrey ml-2 cursor" @click="$emit('showVerbatimTable', item)"  small>mdi-comment-outline</v-icon>
      </template>
    </WInfiniteScroll>
  </div>
</template>

<script>
  import _sortBy from 'lodash/sortBy'
  import { mapGetters } from 'vuex';

  export default {
    name: "ExpectationTopicRankingTable",
    props: ['ranking'],
    data() {
      return {
        belowOnePercentLabel: '<1%'
      }
    },
    computed: {
      ...mapGetters([
        'fullScreenMode'
      ]),
      headers() {
        return [
          {
            name: 'priority',
            title: this.$t('rank'),
            width: "70px",
            class: [ 'flex-shrink-0', 'text-center' ]
          },
          {
            name: 'name',
            title: this.$t('topic'),
            width: "150px",
            class: [ 'flex-shrink-0', 'text-left' ]
          },
          {
            name: 'weight',
            title: this.$t('customer_expectations_level'),
            class: [ 'flex-grow-1', 'text-left', 'pr-3' ]
          }
        ]
      },
      rows() {
        return this.ranking?.filter(item => item.weight)
      },
      formatedRows() {
        return _sortBy(this.rows, item => -item.weight)?.map((item, index) => ({
          ...item,
          priority: index + 1
        }))
      },
      maxWeight() {
        return Math.max(...this.rows.map(item => item.weight))
      }
    }
  }
</script>

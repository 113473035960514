<template>
    <div ref="voterRow" :data-voter-unique-id="voter.uniqueId" class="voter-item voter-item" @mouseover="hover = true" @mouseleave="hover = false">
      <div class="top c-grey fb-14 lh-17 d-flex align-center">

        <VoterProcessingStatusChip
          v-if="voterProcessingStatus && this.currentDashboardInsatisfactionNotificationEnabled"
          :voterNeedsProcessing="voterNeedsProcessing"
          :voterProcessingStatus="voterProcessingStatus"
          :voterInsatisfactionForCurrentGroup="voterInsatisfactionForCurrentGroup"
          :isConversationManagedByCurrentUser="isConversationManagedByCurrentUser"
          :currentUserNeedValidationForCampaign="currentUserNeedValidationForCampaign(voter.campaignId)"
          :hasVoterAutomatedMessageTreatmentScheduled="voterMixin_hasVoterAutomatedMessageTreatmentScheduled"
        />

        <span class="mr-auto">
          <span v-if="voterName !== 'anonymized'">
            {{ voterName }}
          </span>
          <span v-else>
            xxxxx
          </span>
          <WTrustvilleBadge
            class="pl-1"
            :hover="hover"
            :whiteLabel="whiteLabelForVoterCampaign"
            :trustvillePublicState="trustvillePublicState"
            :trustvillePublicLabel="trustvillePublicLabel"
            :voterSource="getVoter?.source"
          />
        </span>
        <div class="f-12">
          <v-rating
            v-if="voterScale === 5 && voterScoreExist"
            class="w-rating"
            background-color="grey lighten-2"
            readonly
            :value="Math.round(voterScore * 2) / 2"
            half-increments
            length="5"
            size="15"
            :color="$colors.gold"
          ></v-rating>
          <WScore
            v-if="voterScale !== 5 && voterScoreExist"
            :voterScore="voterScore"
            :scale="voterScale"
            :insatisfaction-status="voter.insatisfactionStatus"
          />
          <span v-if="!voterScoreExist" class="fs-13">
            {{ $t('nc') }}
          </span>
        </div>
      </div>

      <div class="d-flex align-center pt-2 pb-3 c-grey f-13 lh-18">
        <span class="verbatim">
          {{ voter.mainVerbatimContent }}
        </span>
        <ProcessingStatusIcon v-if="this.currentDashboardInsatisfactionNotificationEnabled"
          :voter="voter"
        >
        </ProcessingStatusIcon>
      </div>

      <div class="bottom f-12 lh-10 c-lightgrey lh-16" :class="{ smartwrap: !hover && !isActive }" :style="{ 'max-width': $vuetify.breakpoint.smAndDown ? '90vw' : '25vw' }">

        <w-date :date="voter.createdAt" font="f-12" :displayTime="true" :icon="voter.isRenotation ? 'mdi-update' : 'mdi-clock-outline'" />

        <span class="pl-1">
          <WIcon
            icon="map-marker"
            :icon-style="{fontSize: '12px'}"
          />
          {{ voter.placesName }}
        </span>

        <span class="pl-1" v-if="isDashboardMulti && campaignName">
          <v-icon small style="font-size: 14px; position: relative; bottom: 1px">mdi-vote-outline</v-icon>
          {{ campaignName }}
        </span>
      </div>
    </div>
</template>

<script>
  import { mapGetters } from "vuex"

  import ProcessingStatusIcon from './ProcessingStatusIcon'
  import VoterProcessingStatusChip from './VoterProcessingStatusChip'
  import VoterMixin from '../../voter_mixin'

  export default {
    name: "VoterItem",
    props: [ 'isActive' ],
    data() {
      return {
        hover: false
      }
    },
    mixins: [
      VoterMixin,
    ],
    components: {
      ProcessingStatusIcon,
      VoterProcessingStatusChip
    },
    computed: {
      ...mapGetters([
        'currentUserNeedValidationForCampaign',
        'currentDashboardCampaignWithId',
        'currentDashboardInsatisfactionNotificationEnabled',
        'isDashboardMulti',
        'currentVoterHasVoterAutomatedMessageTreatmentScheduled',
        'trustvilleStateWithLabel',
        'currentDashboardWhiteLabel'
      ]),
      campaignName() {
        return this.currentDashboardCampaignWithId(this.voter.campaignId)?.name
      }
    },
  }
</script>

<style lang='stylus' scoped>
  .smartwrap
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

  .verbatim
    margin-right: auto

  .process-status
    margin-left: auto

  .voter-item
    position: relative
    padding: 8px 0px
    cursor: pointer
</style>

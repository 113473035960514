import { render, staticRenderFns } from "./CampaignTableWidget.vue?vue&type=template&id=8731ff5e"
import script from "./CampaignTableWidget.vue?vue&type=script&lang=js"
export * from "./CampaignTableWidget.vue?vue&type=script&lang=js"
import style0 from "./CampaignTableWidget.vue?vue&type=style&index=0&id=8731ff5e&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
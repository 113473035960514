<template>
  <div class="question-tree">
    <template v-for="(question, idx) in questions">
      <template v-if="isProductQuestion(question) && answersForQuestion[question.id]">
        <ProductQuestionAnswers
          :voter="voter"
          :question="question"
          :answer="answersForQuestion[question.id][0]"
          :prospect-fields="prospectFields"
          :fields-customization="fieldsCustomization"
          :pictures="pictureUrlsForQuestion[question.id]"
          :g-brand-name="gBrandName"
          :topicsVerbatims="topicsVerbatimForQuestion[question.id]"
        />
      </template>

      <div
        v-if="shouldShowQuestion(question)"
        :class="['question-filled', 'question-filled-level-' + questionLevel]"
        :key="question.id"
      >
        <QuestionTitle
          :voter="voter"
          :question="question"
          :prospect-fields="prospectFields"
          :fields-customization="fieldsCustomization"
          :g-brand-name="gBrandName"
          :position="questionLevel === 0 ? idx + 1 : null"
          :class="{ 'mt-1': idx > 0 }"
        />

        <template v-if="question.type === 'MultipleCriteriaQuestion'">
          <QuestionTree
            :voter="voter"
            :questions="question.markQuestions"
            :answers="answers"
            :pictures="pictures"
            :question-level="questionLevel + 1"
            class="mt-2"
          />
        </template>

        <template v-else>
          <QuestionAnswer
            class="mt-1"
            :question="question"
            :answers="answersForQuestion[question.id]"
            :pictures="pictureUrlsForQuestion[question.id]"
            :topicsVerbatims="topicsVerbatimForQuestion[question.id]"
            :voter="voter"
          />
        </template>

        <template v-if="question.questionConditions">
          <QuestionTree
            :voter="voter"
            :questions="question.questionConditions.map((q) => q.qcmQuestion)"
            :answers="answers"
            :is-question-condition="true"
            :question-level="questionLevel + 1"
          />
        </template>
      </div>

      <div
        v-else-if="
          !voterIsPublicPlatform &&
          (totalQuestions > idx + 1 &&
          (idx === 0 || answersForQuestion[questions[idx - 1].id]))
          && question.subtype !== 'ProductsQuestion'
        "
        :class="[
          'question-not-filled',
          'question-not-filled-level-' + questionLevel,
        ]"
      >
        [...]
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import QuestionTitle from './QuestionTitle'
import QuestionAnswer from './QuestionAnswer'
import VoterMixin from '../../../voter_mixin'
import ProductQuestionAnswers from "./QuestionAnswer/ProductQuestionAnswers";

export default {
  name: 'QuestionTree',
  components: {
    ProductQuestionAnswers,
    QuestionTitle,
    QuestionAnswer,
  },
  mixins: [
    VoterMixin
  ],
  props: {
    questions: { required: true, type: Array },
    answers: { required: true, type: Array },
    pictures: { required: false, type: Array },
    topicsVerbatims: {
      required: false,
      type: Array,
      default: () => []
    },
    //isQuestionCondition: { required: false, type: Boolean, default: false },
    questionLevel: { required: false, type: Number, default: 0 },
    prospectFields: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
    fieldsCustomization: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
    gBrandName: { required: false, type: String, default: '' }
  },
  methods: {
    shouldShowQuestion(question) {
      return (
        !this.isProductQuestion(question) &&
        (
          (this.isMultipleCriteriaQuestion(question) && this.answersForMultipleCriteriaQuestion(question)) ||
          (!this.isMultipleCriteriaQuestion(question) && this.answersForQuestion[question.id])
        )
      );
    },
    isProductQuestion(question) {
      return question.subtype === 'ProductsQuestion'
    },
    isMultipleCriteriaQuestion(question) {
      return question.type === 'MultipleCriteriaQuestion'
    },
    answersForMultipleCriteriaQuestion(question) {
      const answersForMultipleCriteriaQuestion = {}

      question.markQuestions?.forEach((q) => {
        const answers = this.answers.filter((a) => a.questionId === q.id)
        if (answers.length > 0) {
          answersForMultipleCriteriaQuestion[q.id] = answers
        }
      })

      return Object.values(answersForMultipleCriteriaQuestion).length > 0
    }
  },
  computed: {
    answersForQuestion() {
      const answersForQuestion = {}

      this.questions?.forEach((question) => {
        const answers = this.answers.filter(
          (answer) => answer.questionId === question.id
        )
        answersForQuestion[question.id] = answers.length > 0 ? answers : null
      })

      return answersForQuestion
    },
    pictureUrlsForQuestion() {
      const pictureUrlsForQuestion = {}

      if (this.pictures && this.pictures.length) {
        this.questions?.forEach((question) => {
          const pictures = this.pictures.filter(
            (picture) => picture.questionId === question.id
          )

          pictureUrlsForQuestion[question.id] = pictures.length > 0 ? pictures.map(picture => picture) : null
        })
      }

      return pictureUrlsForQuestion
    },
    topicsVerbatimForQuestion() {
      const topicsVerbatimForQuestion = {}

      this.questions?.forEach((question) => {
        const topicsVerbatims = this.topicsVerbatims.filter(
         (topicsVerbatim) => topicsVerbatim?.verbatimQuestionId === question.id
        )
        topicsVerbatimForQuestion[question.id] =
          topicsVerbatims.length > 0 ? topicsVerbatims : []
      })

      return topicsVerbatimForQuestion
    },
    totalQuestions() {
      return this.questions.length
    }
  }
}
</script>

<style lang="stylus" scoped>
.question-tree
  .question-not-filled
    margin-top: 20px

  .question-filled-level-0:first-of-type
    margin-top: 0px

  .question-filled-level-0
    margin-top: 20px

  .question-filled-level-1:first-of-type
    margin-top: 10px

  .question-filled-level-1
    margin-top: 10px

  .question-filled-level-2:first-of-type
    margin-top: 10px

  .question-filled-level-3
    margin-top: 10px
</style>

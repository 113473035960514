<template>
  <WStatsWidget
    v-if="topic === undefined"
    :title="title"
    height="200px"
    :cols="12"
    :loading="true"
    :empty="false"
  />
  <RepartitionWidget
    v-else
    :title="title"
    :campaign="campaign"
    :basedRequest="nisVoterProportions === null ? basedRequest : undefined"
    :nbPromoters="nisVoterProportions ? nisVoterProportions.nbPromoters : undefined"
    :nbNeutrals="nisVoterProportions ? nisVoterProportions.nbNeutrals : undefined"
    :nbDetractors="nisVoterProportions ? nisVoterProportions.nbDetractors : undefined"
    :promoters-label="nisVoterProportions ? label(nisVoterProportions.nbPromoters) : undefined"
    :neutrals-label="nisVoterProportions ? label(nisVoterProportions.nbNeutrals) : undefined"
    :detractors-label="nisVoterProportions ? label(nisVoterProportions.nbDetractors) : undefined"
    :promoters-tooltip="promotersTooltip"
    :neutrals-tooltip="neutralsTooltip"
    :detractors-tooltip="detractorsTooltip"
    :promoters-legend="promotersLegend"
    :neutrals-legend="neutralsLegend"
    :detractors-legend="detractorsLegend"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import RepartitionWidget from "@statistics/shared/widgets/RepartitionWidget"
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'
  import { voterAvgScoreRepartitions } from '@platform/pages/dashboard/shared/constants.js'

  export default {
    name: "TopicRepartitionWidget",
    mixins: [
      WidgetMixin,
      NisMixin,
    ],
    components: {
      RepartitionWidget
    },
    props: {
      campaign: { type: Object, required: true },
      topic: { type: Object },
      nisVoterProportions: { type: Object, default: null },
      nisSummary: { type: Object, default: null },
    },
     computed: {
      ...mapGetters([
        'dashboardFilterRequest'
      ]),
      promotersTooltip() {
        return this.nisVoterProportions && this.nisSummary ? this.tooltip(
          this.nisVoterProportions.nbPromoters,
          this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisSummary).promoters
        ) : undefined
      },
      neutralsTooltip() {
        return this.nisVoterProportions && this.nisSummary ? this.tooltip(
          this.nisVoterProportions.nbNeutrals,
          this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisSummary).neutrals
        ) : undefined
      },
      detractorsTooltip() {
        return this.nisVoterProportions && this.nisSummary ? this.tooltip(
          this.nisVoterProportions.nbDetractors,
          this.nisMixin_currentTransactionalUnitData(this.campaign, this.nisSummary).detractors
        ) : undefined
      },
      columns() {
        return this.campaign.avgScoreScale.max === 5 ?
          [
            {
              "nb_review_by_avg_score_topic_": {
                topic_id: this.topic.id, ...voterAvgScoreRepartitions.detractors5
              }
            },
            {
              "nb_review_by_avg_score_topic_": {
                topic_id: this.topic.id, ...voterAvgScoreRepartitions.neutrals5
              }
            },
            {
              "nb_review_by_avg_score_topic_": {
                topic_id: this.topic.id, ...voterAvgScoreRepartitions.promoters5
              }
            }
          ] :
          [
            {
              "nb_review_by_avg_score_topic_": {
                topic_id: this.topic.id, ...voterAvgScoreRepartitions.detractors10
              }
            },
            {
              "nb_review_by_avg_score_topic_": {
                topic_id: this.topic.id, ...voterAvgScoreRepartitions.neutrals10
              }
            },
            {
              "nb_review_by_avg_score_topic_": {
                topic_id: this.topic.id, ...voterAvgScoreRepartitions.promoters10
              }
            }
          ]
      },
      basedRequest() {
        return this.dashboardFilterRequest.select({
          voters: this.columns
        })
      },
      title() {
        return this.topic === undefined ?
               this.$t('repartition_widget_title') :
               `${this.$t('repartition_widget_title')} - ${this.topic.name}`
      },
      promotersLegend() {
        return this.$helpers.i18n.scoreRange(this.campaign?.avgScoreScale?.promoters)
      },
      neutralsLegend() {
        return this.$helpers.i18n.scoreRange(this.campaign?.avgScoreScale?.neutrals)
      },
      detractorsLegend() {
        return this.$helpers.i18n.scoreRange(this.campaign?.avgScoreScale?.detractors)
      }
    },
    methods: {
      label(count) {
        return this.$options.filters.toPercent(
          count / (this.nisVoterProportions.nbPromoters + this.nisVoterProportions.nbNeutrals + this.nisVoterProportions.nbDetractors)
        ).toString()
      },
      tooltip(count, transactionalUnitValue) {
        const revenue = this.nisMixin_transactionalUnit([this.campaign]).currency ?
                        transactionalUnitValue.toLocaleString(
                          this.$i18n.locale,
                          {
                            style: 'currency',
                            currency: this.nisMixin_transactionalUnit([this.campaign]).currency,
                            maximumFractionDigits: 2
                          }
                        ) :
                        transactionalUnitValue.toFixed(1).replaceAll('.', ',')

        if (this.isLcl) {
          return this.$t(`topic_repartition_widget_${this.nisMixin_transactionalUnit([this.campaign]).nisColumnName}_label_lcl`, {
            count,
            percentage: this.label(count, transactionalUnitValue),
            transactional_unit_value: revenue
          })
        } else {
          return this.$t(`topic_repartition_widget_${this.nisMixin_transactionalUnit([this.campaign]).nisColumnName}_label`, {
            count,
            percentage: this.label(count, transactionalUnitValue),
            transactional_unit_value: revenue
          })
        }
      },
    }
  }
</script>
